import React from "react";
import { connect } from "react-redux";
import { firestore } from "../utils/firebase";
import ReactBSAlert from "react-bootstrap-sweetalert";
import NotificationAlert from "react-notification-alert";
import ReactTable from "react-table";
import { collection, addDoc, deleteDoc, doc } from 'firebase/firestore'

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Form,
  UncontrolledTooltip,
} from "reactstrap";
import { Component } from "react";
import ImageUpload from "../components/CustomUpload/ImageUpload";

class Coupons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      affiliate: '',
      code: '',
      value: 0,
      visible: true,
      data: this.props.coupons.map(doc => {
        return {
          id: doc.id,
          affiliate: doc.data().affiliate,
          code: doc.data().code,
          value: `${doc.data().value * 100}%`,
          actions: (
            // we've added some custom button actions
            <div className="actions-right">

              {/* use this button to add a edit kind of action */}
              <Button
                className="btn-link btn-icon"
                color="success"
                size="sm"

              >
                <i className="tim-icons icon-pencil" />
              </Button>{" "}
              {/* use this button to remove the data row */}
              <Button
                className="btn-link btn-icon"
                color="danger"
                size="sm"
                onClick={() => this.removeConsultant(doc.id)}
              >
                <i className="tim-icons icon-simple-remove" />
              </Button>{" "}
            </div>
          )
        };
      }),
    };
  }

  createConsultant = async () => {

    await addDoc(collection(firestore, "coupons"), {
      affiliate: this.state.affiliate,
      code: this.state.code.toUpperCase(),
      value: parseFloat(this.state.value),
    }).then((doc) => {
      this.hideAlert();
      this.setState({data: this.props.coupons.map(doc => {
        return {
          id: doc.id,
          affiliate: doc.data().affiliate,
          code: doc.data().code,
          value: `${doc.data().value * 100}%`,
          actions: (
            // we've added some custom button actions
            <div className="actions-right">

              {/* use this button to add a edit kind of action */}
              <Button
                className="btn-link btn-icon"
                color="success"
                size="sm"

              >
                <i className="tim-icons icon-pencil" />
              </Button>{" "}
              {/* use this button to remove the data row */}
              <Button
                className="btn-link btn-icon"
                color="danger"
                size="sm"
                onClick={() => this.removeConsultant(doc.id)}
              >
                <i className="tim-icons icon-simple-remove" />
              </Button>{" "}
            </div>
          )
        };
      }),})
      this.notify("bl", "The Coupon was successfully created");
    });


    // firebase
    //   .firestore()
    //   .collection("salesConsultants")
    //   .add({
    //     firstName: this.state.firstName,
    //     lastName: this.state.lastName,
    //     email: this.state.email,
    //     telephone: this.state.telephone,
    //     image: this.state.image,
    //   })
    //   .then((doc) => {
    //     this.hideAlert();
    //     this.notify("bl", "The Sales Consultant was successfully created");
    //   });
  };

  removeConsultant = async (id) => {

    await deleteDoc(doc(firestore, 'coupons', id)).then(() => {
      this.notify("bl", "The Coupon was successfully REMOVED");
      this.setState({data: this.props.coupons.map(doc => {
        return {
          id: doc.id,
          affiliate: doc.data().affiliate,
          code: doc.data().code,
          value: `${doc.data().value * 100}%`,
          actions: (
            // we've added some custom button actions
            <div className="actions-right">

              {/* use this button to add a edit kind of action */}
              <Button
                className="btn-link btn-icon"
                color="success"
                size="sm"

              >
                <i className="tim-icons icon-pencil" />
              </Button>{" "}
              {/* use this button to remove the data row */}
              <Button
                className="btn-link btn-icon"
                color="danger"
                size="sm"
                onClick={() => this.removeConsultant(doc.id)}
              >
                <i className="tim-icons icon-simple-remove" />
              </Button>{" "}
            </div>
          )
        };
      })})
    })
    // firebase.firestore()
    //   .collection('salesConsultants')
    //   .doc(id)
    //   .delete()
    //   .then(() => {
    //     this.notify("bl", "The Sponsor was successfully REMOVED");
    //   })
  }

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  setImage = (url, type) => {
    this.state.image = url;
  };

  removeImage = () => {
    this.state.image = "";
  };

  notify = (place, message) => {
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>{message}</div>
        </div>
      ),
      type: "success",
      icon: "tim-icons icon-bell-55",
      autoDismiss: 7,
    };
    this.refs.notificationAlert.notificationAlert(options);
  };

  render() {
    // if (!this.props.consultants) {
    //   return null;
    // }

    return (
      <div className="content">
        <Card >
          {this.state.alert}
          <NotificationAlert ref="notificationAlert" />
          <CardHeader>
            <h6 className="title d-inline">Affiliate Coupon Codes({this.props.coupons.length})</h6>
            {/* <p className="card-category d-inline">today</p> */}
            <Button
              color="success"
              caret
              id="tooltip342"
              title=""
              className="btn-link btn-icon"
              type="button"
              style={{ float: "right" }}
              onClick={(e) => this.htmlAlert()}
            >
              <i className="tim-icons icon-simple-add" />
            </Button>
            <UncontrolledTooltip delay={0} target="tooltip342">
              Add Coupon Code
            </UncontrolledTooltip>
          </CardHeader>
          <CardBody>
            <ReactTable
              data={this.state.data}
              filterable
              resizable={false}
              columns={[
                {
                  Header: "Affiliate Name",
                  accessor: "affiliate"
                },
                {
                  Header: "Coupon Code",
                  accessor: "code",
                  sortable: false,
                  filterable: false
                },
                {
                  Header: "Value",
                  accessor: "value",
                  sortable: false,
                  filterable: false
                },
                {
                  Header: "Actions",
                  accessor: "actions",
                  sortable: false,
                  filterable: false
                }
              ]}
              defaultPageSize={10}
              showPaginationTop
              showPaginationBottom={false}
              className="-striped -highlight"
            />
          </CardBody>
        </Card>
      </div>
    );
  }

  htmlAlert = () => {
    this.setState({
      alert: (
        <ReactBSAlert
          style={{ display: "block", marginTop: "-300px" }}
          title="ADD A COUPON CODE"
          onConfirm={() => this.createConsultant()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          btnSize=""
          showCancel
          confirmBtnText="Add Consultant!"
          cancelBtnText="Cancel"
        >
          <Form action="#">

            <FormGroup>
              <Input
                placeholder="Affiliate Name"
                onChange={(e) => this.setState({ affiliate: e.target.value })}
                style={{ color: "grey" }}
              />
            </FormGroup>

            <FormGroup>
              <Input
                placeholder="Coupon Code"
                onChange={(e) => this.setState({ code: e.target.value })}
                style={{ color: "grey" }}
              />
            </FormGroup>
            <FormGroup>
              <Input
                placeholder="Value"
                onChange={(e) => this.setState({ value: parseFloat(e.target.value) })}
                style={{ color: "grey" }}
              />
            </FormGroup>

          </Form>
        </ReactBSAlert>
      ),
    });
  };
}
const mapStateToProps = (state) => {
  return { coupons: state.coupons };
};
export default connect(mapStateToProps, {})(Coupons);
