export const packages = [
    {
      value: "Free",
      label: "FREE",
    },
    {
      value: "Basic",
      label: "BASIC",
    },
    {
      value: "Standard",
      label: "STANDARD",
    },
    {
      value: "Premium",
      label: "PREMIUM",
    },
    {
      value: "Commission",
      label: "COMMISSION",
    },
  ];