import React from 'react'
import {
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    Row,
    Col,
  } from "reactstrap";

const PackageCard = ({name, number, icon, message, color}) =>{
    return (
        <Card className="card-stats">
        <CardBody>
          <Row>
            <Col xs="5">
              <div className={`info-icon text-center ${color}`}>
                <i className={icon} />
              </div>
            </Col>
            <Col xs="7">
              <div className="numbers">
                <p className="card-category">{name}</p>
                <CardTitle tag="h3">{number}</CardTitle>
              </div>
            </Col>
          </Row>
        </CardBody>
        <CardFooter>
          <hr />
          <div className="stats">
            {message}
          </div>
        </CardFooter>
      </Card>
    )
}
export default PackageCard;