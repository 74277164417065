import React from "react";
import { connect } from "react-redux";
import firebase from "../utils/firebase";
import ReactBSAlert from "react-bootstrap-sweetalert";
import NotificationAlert from "react-notification-alert";
import moment from "moment";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Table,
  Form,
  UncontrolledTooltip,
} from "reactstrap";
import { Component } from "react";
import ImageUpload from "../components/CustomUpload/ImageUpload";
import { firestore } from '../utils/firebase'
import { collection, addDoc, doc, deleteDoc } from 'firebase/firestore'
class Sponsor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      name: "",
      logo: "",
      name: "",
      visible: true,
    };
  }

  createSponsor = async () => {

    await addDoc(collection(firestore, 'sponsors'), {
      link: this.state.link,
      logo: this.state.logo,
      name: this.state.name,
    }).then((doc) => {
      this.hideAlert();
      this.notify("bl", "The Sponsor was successfully created");
    });

  };

  removeSponsor = async (sponsor) => {

    await deleteDoc(doc(firestore, 'sponsors', sponsor)).then(() => {
      this.notify("bl", "The Sponsor was successfully REMOVED");
    })


  }

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  setImage = (url, type) => {
    this.state.logo = url;
  };

  removeImage = () => {
    this.state.logo = "";
  };

  notify = (place, message) => {
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>{message}</div>
        </div>
      ),
      type: "success",
      icon: "tim-icons icon-bell-55",
      autoDismiss: 7,
    };
    this.refs.notificationAlert.notificationAlert(options);
  };

  render() {
    if (!this.props.sponsors) {
      return null;
    }

    return (
      <div className="content">
        <Card >
          {this.state.alert}
          <NotificationAlert ref="notificationAlert" />
          <CardHeader>
            <h6 className="title d-inline">Sponsors({this.props.sponsors.length})</h6>
            {/* <p className="card-category d-inline">today</p> */}
            <Button
              color="success"
              caret
              id="tooltip342"
              title=""
              className="btn-link btn-icon"
              type="button"
              style={{ float: "right" }}
              onClick={(e) => this.htmlAlert()}
            >
              <i className="tim-icons icon-simple-add" />
            </Button>
            <UncontrolledTooltip delay={0} target="tooltip342">
              Add Sponsor
            </UncontrolledTooltip>
          </CardHeader>
          <CardBody>
            <div className="table-full-width table-responsive">
              <Table>
                <thead>
                  <th>Logo</th>
                  <th>Name</th>
                  <th>Link</th>
                  <th>Action</th>
                </thead>
                <tbody>
                  {this.props.sponsors.map((sponsor) => {
                    return (
                      <tr key={sponsor.id}>
                        <td><img src={sponsor.data().logo} alt="" width='70' /></td>
                        <td>
                          <h6 className="title">{sponsor.data().name}</h6>
                        </td>
                        <td>
                          <h6 className="title">{sponsor.data().link}</h6>
                        </td>
                        <td>
                          <Button
                            className="btn-link btn-icon"
                            color="danger"
                            size="sm"
                            title="Renew"
                            type="button"
                            onClick={() => {
                              this.removeSponsor(sponsor.id)

                            }}><i className="tim-icons icon-simple-remove" /></Button></td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </div>
    );
  }

  htmlAlert = () => {
    this.setState({
      alert: (
        <ReactBSAlert
          style={{ display: "block", marginTop: "-300px" }}
          title="ADD A SPONSOR"
          onConfirm={() => this.createSponsor()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          btnSize=""
          showCancel
          confirmBtnText="Create Task!"
          cancelBtnText="Cancel"
        >
          <Form action="#">
            <ImageUpload
              setImage={this.setImage}
              name={"Sponsor"}
              type={"Profile"}
              index={0}
              removeImage={this.removeImage}
            />
            <FormGroup>
              <Input
                placeholder="Title"
                onChange={(e) => this.setState({ name: e.target.value })}
                style={{ color: "grey" }}
              />
            </FormGroup>
            <FormGroup>
              <Input
                type="text"
                name="link"
                id="exampleText"
                onChange={(e) => this.setState({ link: e.target.value })}
                placeholder="https://sponsor-webseite.com"
                style={{ color: "grey" }}
              />
            </FormGroup>
          </Form>
        </ReactBSAlert>
      ),
    });
  };
}
const mapStateToProps = (state) => {
  return { sponsors: state.sponsors };
};
export default connect(mapStateToProps, {})(Sponsor);
