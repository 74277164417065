import React, { Component } from "react";
import classNames from "classnames";
// react component for creating dynamic tables
import ReactTable from "react-table";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Button
} from "reactstrap";
import { connect } from 'react-redux'



class Bookings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.bookings.map(doc => {
        return {
          id: doc.id,
          // adults:doc.data().adults,
          bookingDate:`${doc.data().bookingDate.toDate()}`,
          bookingName: doc.data().bookingName,
          // children: doc.data().children,
          dateBooked: `${doc.data().dateBooked.toDate()}`,
          destination: doc.data().destination,
          category: doc.data().category,
          packageName: doc.data().packageName,
          total: `$${doc.data().total.toFixed(2)}`,
          actions: (
            // we've added some custom button actions
            <div className="actions-right">
             
              {/* use this button to add a edit kind of action */}
              <Button
                className="btn-link btn-icon"
                color="success"
                size="sm"
                
              >
                <i className="tim-icons icon-pencil" />
              </Button>{" "}
              {/* use this button to remove the data row */}
              {/* <Button
               className="btn-link btn-icon"
                color="danger"
                size="sm"
                
              >
                <i className="tim-icons icon-simple-remove" />
              </Button>{" "} */}
            </div>
          )
        };
      })
    };
  }

  
  render() {
    return (
      <>
        <div className="content">
          <Col md={8} className="ml-auto mr-auto">
            <h2 className="text-center">All {this.props.bookings.length} Activity Bookings</h2>
           
          </Col>
          <Row className="mt-5">
            <Col xs={12} md={12}>
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">React Table</CardTitle>
                </CardHeader>
                <CardBody>
                  <ReactTable
                    data={this.state.data}
                    filterable
                    resizable={false}
                    columns={[
                      {
                        Header: "Destination",
                        accessor: "destination"
                      },
                      {
                        Header: "Package",
                        accessor: "packageName"
                      },
                      {
                        Header: "Name on Booking",
                        accessor: "bookingName"
                      },
                      {
                        Header: "Booking Date",
                        accessor: "bookingDate",
                        filterable: false
                      },
                      {
                        Header:'Category',
                        accessor:'category',
                      },
                      // {
                      //   Header:'Adults',
                      //   accessor:'adults',
                      //   sortable: false,
                      //   filterable: false
                      // },
                      // {
                      //   Header:'Children',
                      //   accessor:'children',
                      //   sortable: false,
                      //   filterable: false
                      // },
                      {
                        Header:'Total Paid',
                        accessor:'total',
                        sortable: false,
                        filterable: false
                      },
                      {
                        Header: "Actions",
                        accessor: "actions",
                        sortable: false,
                        filterable: false
                      }
                    ]}
                    defaultPageSize={10}
                    showPaginationTop
                    showPaginationBottom={false}
                    className="-striped -highlight"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) =>{

 
  return {bookings:state.bookings}
}

export default connect(mapStateToProps, {})(Bookings);
