import React, { Component } from "react";
import { Message } from "../api/messaging";

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Button,
} from "reactstrap";
import Select from "react-select";
import Switch from "react-bootstrap-switch";
import firebase, {firestore, auth,storage} from "../utils/firebase";
import { openingTime } from "../data/openingTime";
import classnames from "classnames";
import {
  FaEnvelope,
  FaDollarSign,
  FaTags,
  Fa500Px,
} from "react-icons/fa";
import defaultImage from '../assets/img/image_placeholder.jpg';
import defaultAvatar from "../assets/img/placeholder.jpg";
import { SiGooglemybusiness } from "react-icons/si";
import Datetime from 'react-datetime';
import { ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";
import { collection, addDoc, Timestamp} from 'firebase/firestore'
class AddEvent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      imageActive: false,
      status: "",
      category: "",
      email: "",
      date: new Date(),
      image: "",
      description: "",
      from: "",
      name: "",
      includes: [],
      to: '',
      bus_departs: 45,
      capacity: 0,
      cost: 0,
      days: 0,
      return: '',
      file: null,
      imagePreviewUrl: this.props.avatar ? defaultAvatar : defaultImage,
      url:'',

    };
    this.handleImageChange = this.handleImageChange.bind(this);
    // this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
  }

  componentDidMount() {
    if (this.state.name != "") {
      this.setState({ imageActive: false });
    }
  }

  handleImageChange(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    this.handleUpload(e.target.files[0])
    reader.onloadend = () => {
      
      this.setState({
        file: file,
        imagePreviewUrl: reader.result
      });
    };
    reader.readAsDataURL(file);
  }


  handleUpload = (image) => {

    const imageRef = ref(storage, `Events/${image.name}`);
    uploadBytes(imageRef, image).then(() => {
      getDownloadURL(imageRef)
        .then((url) => {
          this.setState({image:url})
        })
      console.log('Uploaded a blob or file!',);
    });
    
    // firebase.storage().ref(`Events/${image.name}`)
    //   .put(image)
    //   .then(() => {
    //     firebase.storage()
    //       .ref(`Events`)
    //       .child(image.name)
    //       .getDownloadURL()
    //       .then((url) => {
    //         this.setState({image:url})
    //       });
    //   });
  };


  handleClick() {
    this.refs.fileInput.click();
  }

  handleRemove() {


    const imageRef = ref(storage, `Events/${this.state.file.name}`);
    deleteObject(imageRef).then(() => {
      this.props.removeImage(this.props.index)
      this.setState({
        file: null,
        imagePreviewUrl: this.props.avatar ? defaultAvatar : defaultImage
      });
      this.refs.fileInput.value = null;
    })

    // const imageRef = firebase.storage().ref(`Events/${this.state.file.name}`);

    // imageRef.delete().then(()=>{
     
    //   this.setState({
    //     imgae: '',
    //     imagePreviewUrl: this.props.avatar ? defaultAvatar : defaultImage
    //   });
    //   this.refs.fileInput.value = null;
    // })


   
  }


  addEvent = async (e) => {
    e.preventDefault();
    await addDoc(collection(firestore, "places"), {
      status: this.state.status,
      category: this.state.category,
      email: this.state.email,
      date: Timestamp.fromDate(this.state.date),
      image: this.state.image,
      description: this.state.description,
      from: this.state.from,
      name: this.state.name,
      includes: this.state.includes,
      to: this.state.to,
      bus_departs: this.state.bus_departs,
      capacity: this.state.capacity,
      cost: this.state.cost,
      days: this.state.days,
      return: this.state.return,
    }).then((doc) => {
      this.sendMessage(doc.id, this.state.name, "Event");
      this.clearForm();
    });
  
      
  };

  clearForm = () => {
    this.setState({
      status: "",
      category: "",
      email: "",
      date: new Date(),
      image: "",
      description: "",
      from: "",
      name: "",
      includes: [],
      to: '',
      bus_departs: 45,
      capacity: 0,
      cost: 0,
      days: 0,
      return: '',
    });
  };

  sendMessage = (id, name, category) => {
    Message.post('/send', {
      notification: {
        title: category === 'Destination' ? "New Attraction Added" : "New Event Added",
        body: `${name} was added to the Island Destinations App`,
        sound: "default"
      },
      data: {
        id: id,
        category: category
      },
      // to:"ehDYFK7l6kRAo-5SC7cKlY:APA91bEPlm6bLcvegrqqjguDR1IRre1Mdrzc3ga52C5ylUuS_QW1P3KEjGC59HzjRwjj5V6b5A2cYQdjHInOYmGFll7UdvP3pz1FDDp-nlO45kld7cJzFqQ5dLm48JKQI8UjnOkBpklc",
      to: '/topics/Destinations',
      priority: "high"

    })

  }

  addField = () => {
    this.setState({ images: [...this.state.images, ""] });
  };

  addTime = () => {
    this.setState({ openingHours: [...this.state.openingHours, {}] });
  };
  addPricing = () => {
    this.setState({ includes: [...this.state.includes, ''] });
  };

  render() {
    const options = {
      mediaType: 'photo',
      maxWidth: 1080,
      maxHeight: 580,
      quality: 0.5,
    }
    return (
      <div className="content">
        <Row>
          <Col lg="8" md="6">
            <Card >
              <CardHeader>
                <CardTitle tag="h5">General Information</CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md={12}>
                    <InputGroup
                      className={classnames(this.state.nameState, {
                        "input-group-focus": this.state.nameFocus,
                      })}
                    >
                      <InputGroupAddon addonType="prepend" >
                        <InputGroupText>
                          <SiGooglemybusiness />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        name="name"
                        placeholder="Name"
                        defaultValue={this.state.name}
                        type="text"

                        onChange={(e) =>
                          this.setState({
                            name: e.target.value,
                            imageActive: false,
                          })
                        }
                        onFocus={(e) => this.setState({ nameFocus: true })}
                        onBlur={(e) => this.setState({ nameFocus: false })}
                      />
                      {this.state.firstnameState === "has-danger" ? (
                        <label className="error">This field is required.</label>
                      ) : null}
                    </InputGroup>
                  </Col>

                  <Col md={6}>
                    <InputGroup
                      className={classnames(this.state.emailState, {
                        "input-group-focus": this.state.emailFocus,
                      })}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <FaEnvelope />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        name="email"
                        placeholder="Email"
                        type="email"
                        onChange={(e) =>
                          this.setState({ email: e.target.value })
                        }
                        onFocus={(e) => this.setState({ emailFocus: true })}
                        onBlur={(e) => this.setState({ emailFocus: false })}
                      />
                      {this.state.firstnameState === "has-danger" ? (
                        <label className="error">This field is required.</label>
                      ) : null}
                    </InputGroup>
                  </Col>

                  <Col md={3}>
                    <Select
                      className="react-select info"
                      classNamePrefix="react-select"
                      name="category"
                      defaultValue={this.state.category}
                      onChange={(e) => this.setState({ category: e.value })}
                      options={[
                        {
                          value: "Camping",
                          label: "Camping",
                        },
                        {
                          value: "Excursion",
                          label: "Excursion",
                        },
                        {
                          value: "Party",
                          label: "Party",
                        },

                      ]}
                      placeholder="Select Category"
                    />

                  </Col>

                  <Col md={3}>
                    <Select
                      className="react-select info"
                      classNamePrefix="react-select"
                      name="category"
                      defaultValue={this.state.category}
                      onChange={(e) => this.setState({ status: e.value })}
                      options={[
                        {
                          value: "Active",
                          label: "Active",
                        },
                        {
                          value: "Inactive",
                          label: "Inactive",
                        },

                      ]}
                      placeholder="Select Status"
                    />

                  </Col>


                  <Col md={6}>
                    <InputGroup
                      className={classnames(this.state.costState, {
                        "input-group-focus": this.state.costFocus,
                      })}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <FaDollarSign />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        name="cost"
                        placeholder="Cost"
                        type="curency"
                        value={this.state.cost}
                        onChange={(e) =>
                          this.setState({ cost: e.target.value === '' ? 0 : parseFloat(e.target.value) })
                        }
                        onFocus={(e) => this.setState({ costFocus: true })}
                        onBlur={(e) => this.setState({ costFocus: false })}
                      />
                      {this.state.firstnameState === "has-danger" ? (
                        <label className="error">This field is required.</label>
                      ) : null}
                    </InputGroup>
                  </Col>

                  <Col md={6}>
                    <InputGroup
                      className={classnames(this.state.capacityState, {
                        "input-group-focus": this.state.capacityFocus,
                      })}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <Fa500Px />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        name="capacity"
                        placeholder="Capacity"
                        type="numeber"
                        value={this.state.capacity}
                        onChange={(e) =>
                          this.setState({ capacity: e.target.value === '' ? 0 : parseFloat(e.target.value) })
                        }
                        onFocus={(e) => this.setState({ capacityFocus: true })}
                        onBlur={(e) => this.setState({ capacityFocus: false })}
                      />
                      {this.state.firstnameState === "has-danger" ? (
                        <label className="error">This field is required.</label>
                      ) : null}
                    </InputGroup>
                  </Col>
                  {this.state.category === 'Party' ? null :
                    <Col md={4}>
                      <InputGroup
                        className={classnames(this.state.fromState, {
                          "input-group-focus": this.state.fromFocus,
                        })}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <Fa500Px />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          name="from"
                          placeholder="Start Location"
                          type="numeber"
                          onChange={(e) =>
                            this.setState({ from: e.target.value })
                          }
                          onFocus={(e) => this.setState({ fromFocus: true })}
                          onBlur={(e) => this.setState({ fromFocus: false })}
                        />
                        {this.state.firstnameState === "has-danger" ? (
                          <label className="error">This field is required.</label>
                        ) : null}
                      </InputGroup>
                    </Col>

                  }
                  <Col md={4}>
                    <InputGroup
                      className={classnames(this.state.toState, {
                        "input-group-focus": this.state.toFocus,
                      })}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <Fa500Px />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        name="to"
                        placeholder={this.state.category === 'Party' ? 'Location' : 'Destination'}
                        type="text"
                        onChange={(e) =>
                          this.state.category === 'Party' ? this.setState({ to: e.target.value, from: e.target.value }) : this.setState({ to: e.target.value })

                        }
                        onFocus={(e) => this.setState({ toFocus: true })}
                        onBlur={(e) => this.setState({ toFocus: false })}
                      />
                      {this.state.firstnameState === "has-danger" ? (
                        <label className="error">This field is required.</label>
                      ) : null}
                    </InputGroup>
                  </Col>


                  <Col md={4} style={{ marginBottom: 10 }}>
                    <Datetime initialValue={new Date()} value={this.state.date} onChange={(e) => this.setState({ date: e })} placeholder="Date" />
                  </Col>


                  <Col md={4} style={{ marginBottom: 10 }}>
                    <Select
                      className="react-select info"
                      classNamePrefix="react-select"
                      name="openingTime"
                      // value={this.state.bus_departs}
                      onChange={(e) => {
                        this.setState({
                          bus_departs: e.value,
                        });
                      }}
                      options={openingTime}
                      placeholder="Opening Time"
                    />
                  </Col>

                  <Col md={4} style={{ marginBottom: 10 }}>
                    <Select
                      className="react-select info"
                      classNamePrefix="react-select"
                      name="openingTime"
                      // value={this.state.bus_departs}
                      onChange={(e) => {
                        this.setState({
                          return: e.value,
                        });
                      }}
                      options={openingTime}
                      placeholder={this.state.category === 'Party' ? 'End Time' : 'Return Time'}
                    />
                  </Col>






                  <Col lg="12" md="12">

                    <Input
                      type="textarea"
                      id="description"
                      defaultValue={this.state.description}
                      onChange={(e) =>
                        this.setState({ description: e.target.value })
                      }
                      placeholder="Description"
                      style={{ padding: 10, height: 192, maxHeight: 192 }}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col lg="4" md="6">
            <Card style={{}}>
              <CardHeader>
                <CardTitle tag="h5"> Profile Picture</CardTitle>
              </CardHeader>
              <CardBody className="text-center">
                <div className="fileinput text-center">
                  <input type="file" onChange={this.handleImageChange} ref="fileInput" />
                  <div className={"thumbnail" + (this.props.avatar ? " img-circle" : "")}>
                    <img src={this.state.imagePreviewUrl} alt="..." />
                  </div>
                  <div>
                    {this.state.image === '' ? (
                      <Button
                        color={this.props.addBtnColor}
                        className={this.props.addBtnClasses}
                        onClick={() => this.handleClick()}
                        disabled={this.props.active}
                      >
                        {this.props.avatar ? "Add Photo" : "Select image"}
                      </Button>
                    ) : (
                      <span>
                        <Button
                          color={this.props.changeBtnColor}
                          className={this.props.changeBtnClasses}
                          onClick={() => this.handleClick()}
                        >
                          Change
                        </Button>
                        {this.props.avatar ? <br /> : null}
                        <Button
                          color={this.props.removeBtnColor}
                          className={this.props.removeBtnClasses}
                          onClick={() => this.handleRemove()}
                        >
                          <i className="fa fa-times" /> Remove
                        </Button>
                      </span>
                    )}
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>



        </Row>

        <Row>


          <Col md={'12'}>
            <Card style={{ minHeight: 267 }}>
              <CardHeader>
                <CardTitle tag="h5">What's Included</CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  {this.state.includes.map((time, i) => (

                    <Col md='3'>
                      <Row style={{ marginBottom: 20 }}>


                        <Col md={12}>


                          <InputGroup
                            className={classnames(this.state.pricingNameState, {
                              "input-group-focus": this.state.pricingNameFocus,
                            })}
                          >
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <FaTags />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              name="pricingName"
                              placeholder="Included Name"
                              type="text"
                              onChange={(e) => {
                                this.state.pricing[i] = e.target.value;
                                this.setState({
                                  pricing: [...this.state.pricing],
                                });
                              }}
                              onFocus={(e) => this.setState({ pricingNameFocus: true })}
                              onBlur={(e) => this.setState({ pricingNameFocus: false })}
                            />
                            {this.state.pricingNameState === "has-danger" ? (
                              <label className="error">This field is required.</label>
                            ) : null}
                          </InputGroup>
                        </Col>







                        


                        


                       

                      </Row>
                    </Col>

                  ))}

                </Row>
                <div>
                  <Button onClick={this.addPricing}>Add New Pricing</Button>
                </div>
              </CardBody>
            </Card>
          </Col>

          <Col>
          </Col>
        </Row>

        <Row>
          <Col
            lg={6}
            className="ml-auto mr-auto"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Button color="success" onClick={(e) => this.addEvent(e)}>
              Add Event
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

export default AddEvent;
