import axios from 'axios'

const token = 'AAAAqJjOJ_Q:APA91bHp-boVdHYX2mCipjLK2YIqkoocFdGE8GwoHm36_09IzZ7S9ZYXPcHkV1K-9mjP1mVfzt0F58BRBSoQA1HxbtTdGwx9Mo2dYr1vUtBIVl5hM2Hm9JbwvCrKtnKM8rvfuTbipK-s'
export const Message = axios.create({
    baseURL: `https://fcm.googleapis.com/fcm`,
    headers: {
        Accept: "Content-Type: application/json",
        Authorization: `Key=${token}`,
    }
})
