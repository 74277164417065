import React, {Component} from 'react'

class Loading extends Component{
    constructor(props){
        super(props)
        this.state ={

        }
    }

    render(){
        return(
            <div>
                Loading...
            </div>
        )
    }
}

export default Loading;