import { useState } from 'react'
import { Upload, message } from 'antd';
import { Row, Col } from 'reactstrap';
import { InboxOutlined } from '@ant-design/icons';
import { storage } from '../utils/firebase';
// import { Colors } from '../commons/Colors';
import { ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";


const { Dragger } = Upload;



const MultipleUpload = ({ destination, images, setImages, pack }) => {


    const [fileList, setFileList] = useState([]);

    const onPreview = async file => {
        let src = file.url;
        if (!src) {
            src = await new Promise(resolve => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow.document.write(image.outerHTML);
    };


    const handleUpload = async ({ onError, onSuccess, file }) => {

        const imageRef = ref(storage, `Destinations/${destination}/${file.name}`)
        
        uploadBytes(imageRef, file).then(() => {
            getDownloadURL(imageRef)
                .then((url) => {
                    images.push(url);
                    setImages(images)
                })
            console.log('Uploaded a blob or file!',);
        });
    }

    const removeImage = async (e) => {

        const imageRef = ref(storage, `Destinations/${this.props.name}/${this.state.file.name}`);
        deleteObject(imageRef).then(() => {
            message.success('Image was removed successfully')
        })

    }

    const fileProps = {
        name: 'file',
        multiple: true,
        accept: ".jpg, .jpeg, .png",
        maxCount: 40,
        onRemove: removeImage,
        listType: "picture-card",
        fileList: fileList,
        onPreview: onPreview,
        customRequest: handleUpload,
        onChange(info) {

            const { status } = info.file;
            setFileList(info.fileList);

            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);

            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };



    return (
        <div style={{ padding: 30 }}>

            <Row>

                <Col md={12}>
                    {images.length > 0 &&
                        <div style={{ position: 'relative' }}>
                            <Dragger {...fileProps} style={{ marginTop: 15, marginBottom: 1 }}>
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text" style={{ color: '#fff' }}>Click or drag file to this area to upload</p>
                                <p tyle={{ color: '#fff' }}>
                                    Supports single or bulk upload for maximum of 10 Images. Strictly prohibit from uploading company data or other
                                    band files
                                </p>
                            </Dragger>
                            <span style={{ fontSize: 12, color: '#fff', fontWeight: '700' }}>{40 - images.length} Images remaining</span>
                        </div>
                    }
                </Col>



            </Row>

        </div>
    )


}





export default MultipleUpload;