import React, { Component } from "react";
import ReactTable from "react-table";
import { Card, CardBody, CardHeader, CardTitle, Row, Col, Button } from "reactstrap";
import { connect } from 'react-redux'



class PaymentRequests extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.paymentRequests.map(doc => {
        return {
          id: doc.id,
          requestBy: doc.data().requestBy,
          date: `${doc.data().date.toDate()}`,
          destinationName: doc.data().destinationName,
          status: doc.data().status,
          amount: `$${doc.data().amount.toFixed(2)}`,
          actions: (
            <div className="actions-right">
              <Button
                className="btn-link btn-icon"
                color="success"
                size="sm"

              >
                <i className="tim-icons icon-pencil" />
              </Button>{" "}
              
            </div>
          )
        };
      })
    };
  }


  render() {
    return (
      <>
        <div className="content">
          <Col md={8} className="ml-auto mr-auto">
            <h2 className="text-center">All {this.props.paymentRequests.length} Payment Requests</h2>

          </Col>
          <Row className="mt-5">
            <Col xs={12} md={12}>
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">React Table</CardTitle>
                </CardHeader>
                <CardBody>
                  <ReactTable
                    data={this.state.data}
                    filterable
                    resizable={false}
                    columns={[
                      {
                        Header: "Destination",
                        accessor: "destinationName"
                      },
                      {
                        Header: "Requested By",
                        accessor: "requestBy"
                      },
                      {
                        Header: "Requested Date",
                        accessor: "date",
                        filterable: false
                      },
                      {
                        Header: 'Status',
                        accessor: 'status',
                      },
                      {
                        Header: 'Amount Request',
                        accessor: 'amount',
                        sortable: false,
                        filterable: false
                      },
                      {
                        Header: "Actions",
                        accessor: "actions",
                        sortable: false,
                        filterable: false
                      }
                    ]}
                    defaultPageSize={10}
                    showPaginationTop
                    showPaginationBottom={false}
                    className="-striped -highlight"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {


  return { paymentRequests: state.paymentRequests }
}

export default connect(mapStateToProps, {})(PaymentRequests);
