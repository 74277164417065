import React from "react";
import { connect } from "react-redux";

// reactstrap components
import {

  Row,
  Col,
} from "reactstrap";


import PackageCard from "../components/DashBoard/PackageCard";
import PerformanceChart from "../components/DashBoard/PerformanceChart";
import ParishChart from "../components/DashBoard/ParishChart";
import RenewalTable from "../components/DashBoard/RenewalTable";
import TaskCard from "../components/DashBoard/TaskCard";


class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bigChartData: "data1",
      freePackage: 0,
      basicPackage: 0,
      standardPackage: 0,
      premiumPackage: 0,
      alert: null,
    };
  }


  
  render() {
    if(!this.props.destinations){
      return null;
    }
    return (
      <>
        <div className="content">
          <Row>
            <Col xs="12">
              <PerformanceChart data={this.props.destinations} loaded={this.props.dataLoaded}/>
            </Col>
            <Col lg="3" md="6">
              <PackageCard
                name="Free Package"
                number={this.props.freeCount}
                icon="tim-icons icon-tag"
                message="These are destinations with no management structure (free entry)."
                color="icon-warning"
              />
            </Col>
            <Col lg="3" md="6">
              <PackageCard
                name="Basic Package"
                number={this.props.basicCount}
                icon="tim-icons icon-bag-16"
                message="These are destinations with a management structure for contact."
                color="icon-primary"
              />
              
            </Col>
            <Col lg="3" md="6">
              <PackageCard
                name="Standard Package"
                number={this.props.standardCount}
                icon="tim-icons icon-shape-star"
                message="Includes the previous package but wants more listed."
                color="icon-success"
              />
            </Col>
            <Col lg="3" md="6">
              <PackageCard
                name="Premium Package"
                number={this.props.premiumCount}
                icon="tim-icons icon-trophy"
                message="Include all other Packages with video shoot and featured benefits."
                color="icon-danger"
              />
            </Col>
            
          </Row>
          <Row>
            <Col lg="5">
              <TaskCard/>
            </Col>
            <Col lg="7">
              <RenewalTable data={this.props.destinations}/>
            </Col>
            <Col xs="12">
            <ParishChart data={this.props.destinations} loaded={this.props.dataLoaded}/>
            </Col>

            
          </Row>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {

  const {destinations, dataLoaded} =state;
  var freeCount=0;
  var basicCount=0;
  var standardCount=0;
  var premiumCount =0;
if(state.destinations){
  state.destinations.map((doc)=>{
    switch (doc.data().package) {
      case "Free":
        freeCount= freeCount+ 1;
        break;
      case "Basic":
        basicCount = basicCount + 1;
        break;
      case "Standard":
        standardCount = standardCount + 1;
        break;
      case "Premium":
        premiumCount = premiumCount + 1;
        break;
      default:
        break;
    }
  })
}

  return { destinations, dataLoaded,freeCount, basicCount,standardCount,premiumCount};
};
export default connect(mapStateToProps)(Dashboard);
