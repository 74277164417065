import React, { Component } from "react";
import { Message } from "../api/messaging";

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Button,
} from "reactstrap";
import Select from "react-select";
import Switch from "react-bootstrap-switch";
import firebase, { auth, firestore } from "../utils/firebase";
import { packages } from "../data/packages";
import { categories } from "../data/categories";
import parishes from "../data/parishes";
import { openingDays } from "../data/openingDays";
import { openingTime } from "../data/openingTime";
import classnames from "classnames";
import {
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaYoutube,
  FaRoad,
  FaCity,
  FaEnvelope,
  FaPhoneAlt,
  FaGlobeAmericas,
  FaDollarSign,
  FaTags
} from "react-icons/fa";
import { BsGeoAlt, BsPerson } from "react-icons/bs";
import { SiGooglemybusiness } from "react-icons/si";
import { MdLocalMovies } from "react-icons/md";
import ImageUpload from "../components/CustomUpload/ImageUpload";
import MultipleUpload from "../components/MultipleUpload";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { doc, addDoc, collection, updateDoc, GeoPoint, deleteDoc, getDocs, onSnapshot, orderBy, query, where, Timestamp } from "firebase/firestore";

class AddDestination extends Component {
  constructor(props) {
    super(props);

    this.state = {
      imageActive: true,
      active: false,
      category: "",
      dateAdded: Timestamp.fromDate(new Date()),
      email: "",
      expiredAt: Timestamp.fromDate(
        new Date(new Date().setFullYear(new Date().getFullYear() + 1))
      ),
      mainPhone: "",
      description: "",
      featured: false,
      latitude: "",
      longitude: "",
      parish: "",
      street: "",
      town: "",
      name: "",
      facebook: "",
      instagram: "",
      twitter: "",
      website: "",
      youtube: "",
      images: [],
      video: "",
      openingHours: [],
      package: "",
      contactFirstName: '',
      contactLastName: '',
      contactPhone: '',
      contactEmail: '',
      pricing: [],
    };
  }

  componentDidMount() {
    if (this.state.name != "") {
      this.setState({ imageActive: false });
    }
  }

  setImage = (url, type) => {
    switch (type) {
      case "Profile":
        this.state.images[0] = url;
        break;
      case "Gallery":
        this.setState({ images: [...this.state.images, url] });
        break;
      default:
        break;
    }
  };

  removePackage = (index) => {

    this.state.pricing.splice(index, 1);

    this.setState({pricing: [...this.state.pricing]})
    // this.notify("bl", "The Package was successfully removed");

  };


  removeImage = (index) => {
    if (index === 0) {
      this.state.images[0] = "";
    } else {
      this.state.images.splice(index);
    }
  };

  setDestinations = async (e) => {
    e.preventDefault();
    const data = await addDoc(collection(firestore, 'places'), {
      active: true,
      category: this.state.category,
      dateAdded: this.state.dateAdded,
      contact: { email: this.state.email, mainPhone: this.state.mainPhone },
      expiredAt: this.state.expiredAt,
      description: this.state.description,
      featured: this.state.featured,
      geocode: new GeoPoint(this.state.latitude, this.state.longitude),
      location: {
        parish: this.state.parish,
        street: this.state.street,
        town: this.state.town,
      },
      name: this.state.name,
      social: {
        facebook: this.state.facebook,
        instagram: this.state.instagram,
        twitter: this.state.twitter,
        website: this.state.website,
        youtube: this.state.youtube,
      },
      images: this.state.images,
      video: this.state.video,
      openingHours: this.state.openingHours,
      package: this.state.package,
      pricing: this.state.pricing,
    })



    if (this.state.package === 'Commission') {
      this.addCommissionUser(data.id)
    }

    this.sendMessage(data.id, this.state.name, "Destination");
    this.addContact(data.id);
    this.clearForm();

  };

  addContact = async (id) => {

    await addDoc(collection(firestore, 'contact-person'), {
      firstName: this.state.contactFirstName,
      lastName: this.state.contactLastName,
      phone: this.state.contactPhone,
      email: this.state.contactEmail,
      destination: id,
    })
  }

  addCommissionUser = async (id) => {

    const coll = collection(firestore, 'commissionUsers')
    const q = query(coll, where('email', '==', this.state.contactEmail))
    onSnapshot(q, async(querySnapshot) => {
      
       if(querySnapshot.docs.length === 0){
        await addDoc(collection(firestore, 'commissionUsers'), {
          dateAdded: Timestamp.fromDate(new Date()),
          email: this.state.contactEmail,
          destination: id,
          role: 'Admin',
          name: `${this.state.contactFirstName} ${this.state.contactLastName}`
        })
       }
     
    })

    
  }


  clearForm = () => {
    this.setState({
      active: false,
      category: "",
      email: "",
      mainPhone: "",
      description: "",
      featured: false,
      latitude: "",
      longitude: "",
      parish: "",
      street: "",
      town: "",
      name: "",
      facebook: "",
      instagram: "",
      twitter: "",
      website: "",
      youtube: "",
      images: [],
      video: "",
      openingHours: [],
      package: "",
      contactFirstName: '',
      contactLastName: '',
      contactPhone: '',
      contactEmail: ''
    });
  };

  sendMessage = (id, name, category) => {
    Message.post('/send', {
      notification: {
        title: category === 'Destination' ? "New Attraction Added" : "New Event Added",
        body: `${name} was added to the Island Destinations App`,
        sound: "default"
      },
      data: {
        id: id,
        category: category
      },
      // to:"ehDYFK7l6kRAo-5SC7cKlY:APA91bEPlm6bLcvegrqqjguDR1IRre1Mdrzc3ga52C5ylUuS_QW1P3KEjGC59HzjRwjj5V6b5A2cYQdjHInOYmGFll7UdvP3pz1FDDp-nlO45kld7cJzFqQ5dLm48JKQI8UjnOkBpklc",
      to: '/topics/Destinations',
      priority: "high"

    })

  }

  addField = () => {
    this.setState({ images: [...this.state.images, ""] });
  };

  addTime = () => {
    this.setState({ openingHours: [...this.state.openingHours, {}] });
  };
  addPricing = () => {
    this.setState({ pricing: [...this.state.pricing, {}] });
  };

  render() {
    const options = {
      mediaType: 'photo',
      maxWidth: 1080,
      maxHeight: 1080,
      quality: 0.5,
    }
    return (
      <div className="content">
        <Row>
          <Col lg="8" md="6">
            <Card style={{ height: 330 }}>
              <CardHeader>
                <CardTitle tag="h5">General Information</CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md={12}>
                    <InputGroup
                      className={classnames(this.state.nameState, {
                        "input-group-focus": this.state.nameFocus,
                      })}
                    >
                      <InputGroupAddon addonType="prepend" >
                        <InputGroupText>
                          <SiGooglemybusiness />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        name="name"
                        placeholder="Name"
                        defaultValue={this.state.name}
                        type="text"

                        onChange={(e) =>
                          this.setState({
                            name: e.target.value,
                            imageActive: false,
                          })
                        }
                        onFocus={(e) => this.setState({ nameFocus: true })}
                        onBlur={(e) => this.setState({ nameFocus: false })}
                      />
                      {this.state.firstnameState === "has-danger" ? (
                        <label className="error">This field is required.</label>
                      ) : null}
                    </InputGroup>
                  </Col>

                  <Col md={6}>
                    <InputGroup
                      className={classnames(this.state.emailState, {
                        "input-group-focus": this.state.emailFocus,
                      })}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <FaEnvelope />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        name="email"
                        placeholder="Email"
                        type="email"
                        onChange={(e) =>
                          this.setState({ email: e.target.value })
                        }
                        onFocus={(e) => this.setState({ emailFocus: true })}
                        onBlur={(e) => this.setState({ emailFocus: false })}
                      />
                      {this.state.firstnameState === "has-danger" ? (
                        <label className="error">This field is required.</label>
                      ) : null}
                    </InputGroup>
                  </Col>
                  <Col md={6}>
                    <InputGroup
                      className={classnames(this.state.phoneState, {
                        "input-group-focus": this.state.phoneFocus,
                      })}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <FaPhoneAlt />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        name="phone"
                        placeholder="Phone Number"
                        type="text"
                        onChange={(e) =>
                          this.setState({ mainPhone: e.target.value })
                        }
                        onFocus={(e) => this.setState({ phoneFocus: true })}
                        onBlur={(e) => this.setState({ phoneFocus: false })}
                      />
                      {this.state.firstnameState === "has-danger" ? (
                        <label className="error">This field is required.</label>
                      ) : null}
                    </InputGroup>
                  </Col>
                  <Col md={6}>
                    <Select
                      className="react-select info"
                      classNamePrefix="react-select"
                      name="category"
                      defaultValue={this.state.category}
                      onChange={(e) => this.setState({ category: e.value })}
                      options={categories}
                      placeholder="Select Category"
                    />
                    <div style={{ marginTop: 10 }}>
                      <Select
                        className="react-select info"
                        classNamePrefix="react-select"
                        name="package"
                        defaultValue={this.state.package[0]}
                        onChange={(e) => this.setState({ package: e.value })}
                        options={packages}
                        placeholder="Select Package"
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <Row>
                      <Col md="6">
                        <p className="category">Active</p>
                        <Switch
                          defaultValue={this.state.active}
                          offColor=""
                          onColor="#00ffff"
                          offText="No"
                          onText="Yes"
                          onChange={(value) => this.setState({ active: !this.state.active })}
                        />
                      </Col>
                      <Col md="6">
                        <p className="category">Featured</p>
                        <Switch
                          defaultValue={this.state.featured}
                          offColor=""
                          onColor=""
                          offText="No"
                          onText="Yes"
                          onChange={(value) =>
                            this.setState({ featured: !this.state.featured })
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col lg="4" md="6">
            <Card style={{ height: 330 }}>
              <CardHeader>
                <CardTitle tag="h5"> Profile Picture</CardTitle>
              </CardHeader>
              <CardBody className="text-center">
                <ImageUpload
                  setImage={this.setImage}
                  active={this.state.imageActive}
                  name={this.state.name}
                  type={"Profile"}
                  index={0}
                  removeImage={this.removeImage}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg="4" md="6">
            <Card>
              <CardHeader>
                <CardTitle tag="h5">Contact Person</CardTitle>
              </CardHeader>
              <CardBody>
                <InputGroup
                  className={classnames(this.state.firstNametState, {
                    "input-group-focus": this.state.firstNameFocus,
                  })}
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <BsPerson />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    name="firstName"
                    placeholder="First Name"
                    type="text"
                    disabled={this.state.package === 'Free' || this.state.package === ''}
                    onChange={(e) => this.setState({ contactFirstName: e.target.value })}
                    onFocus={(e) => this.setState({ firstNameFocus: true })}
                    onBlur={(e) => this.setState({ firstNameFocus: false })}
                  />
                  {this.state.firstnameState === "has-danger" ? (
                    <label className="error">This field is required.</label>
                  ) : null}
                </InputGroup>
                <InputGroup
                  className={classnames(this.state.lastNameState, {
                    "input-group-focus": this.state.lastNameFocus,
                  })}
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <BsPerson />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    name="lastName"
                    placeholder=" Last Name"
                    type="text"
                    disabled={this.state.package === 'Free' || this.state.package === ''}
                    onChange={(e) => this.setState({ contactLastName: e.target.value })}
                    onFocus={(e) => this.setState({ lastNameFocus: true })}
                    onBlur={(e) => this.setState({ lastNameFocus: false })}
                  />
                  {this.state.firstnameState === "has-danger" ? (
                    <label className="error">This field is required.</label>
                  ) : null}
                </InputGroup>

                <InputGroup
                  className={classnames(this.state.contactPhoneState, {
                    "input-group-focus": this.state.contactPhoneFocus,
                  })}
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <FaPhoneAlt />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    name="contactPhone"
                    placeholder="Phone Number"
                    type="text"
                    disabled={this.state.package === 'Free' || this.state.package === ''}
                    onChange={(e) =>
                      this.setState({ contactPhone: e.target.value })
                    }
                    onFocus={(e) => this.setState({ contactPhoneFocus: true })}
                    onBlur={(e) => this.setState({ contactPhoneFocus: false })}
                  />
                  {this.state.firstnameState === "has-danger" ? (
                    <label className="error">This field is required.</label>
                  ) : null}
                </InputGroup>

                <InputGroup
                  className={classnames(this.state.contactEmailState, {
                    "input-group-focus": this.state.contactEmailFocus,
                  })}
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <FaEnvelope />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    name="contactEmail"
                    placeholder="Email Address"
                    type="text"
                    disabled={this.state.package === 'Free' || this.state.package === ''}
                    onChange={(e) =>
                      this.setState({ contactEmail: e.target.value })
                    }
                    onFocus={(e) => this.setState({ contactEmailFocus: true })}
                    onBlur={(e) => this.setState({ contactEmailFocus: false })}
                  />
                  {this.state.firstnameState === "has-danger" ? (
                    <label className="error">This field is required.</label>
                  ) : null}
                </InputGroup>

              </CardBody>
            </Card>
          </Col>
          <Col lg="8" md="6">
            <Card>
              <CardHeader>
                <CardTitle tag="h5">Description</CardTitle>
              </CardHeader>
              <CardBody>
                <Input
                  type="textarea"
                  id="description"
                  defaultValue={this.state.description}
                  onChange={(e) =>
                    this.setState({ description: e.target.value })
                  }
                  placeholder="Description"
                  style={{ padding: 10, height: 192, maxHeight: 192 }}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg="4" md="6">
            <Card>
              <CardHeader>
                <CardTitle tag="h5">Destination Address</CardTitle>
              </CardHeader>
              <CardBody>
                <InputGroup
                  className={classnames(this.state.streetState, {
                    "input-group-focus": this.state.streetFocus,
                  })}
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <FaRoad />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    name="street"
                    placeholder="Street"
                    type="text"
                    onChange={(e) => this.setState({ street: e.target.value })}
                    onFocus={(e) => this.setState({ streetFocus: true })}
                    onBlur={(e) => this.setState({ streetFocus: false })}
                  />
                  {this.state.firstnameState === "has-danger" ? (
                    <label className="error">This field is required.</label>
                  ) : null}
                </InputGroup>
                <InputGroup
                  className={classnames(this.state.townState, {
                    "input-group-focus": this.state.townFocus,
                  })}
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <FaCity />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    name="town"
                    placeholder="Town"
                    type="text"
                    onChange={(e) => this.setState({ town: e.target.value })}
                    onFocus={(e) => this.setState({ townFocus: true })}
                    onBlur={(e) => this.setState({ townFocus: false })}
                  />
                  {this.state.firstnameState === "has-danger" ? (
                    <label className="error">This field is required.</label>
                  ) : null}
                </InputGroup>

                <Select
                  className="react-select info"
                  classNamePrefix="react-select"
                  name="parish"
                  defaultValue={this.state.parish}
                  onChange={(e) => this.setState({ parish: e.value })}
                  options={parishes}
                  placeholder="Select Parish"
                />

                <Row style={{ marginTop: 10 }}>
                  <Col lg="6" md="6">
                    <InputGroup
                      className={classnames(this.state.latitudeState, {
                        "input-group-focus": this.state.latitudeFocus,
                      })}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <BsGeoAlt />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        name="latitude"
                        placeholder="Latitude"
                        type="text"
                        onChange={(e) =>
                          this.setState({ latitude: e.target.value })
                        }
                        onFocus={(e) => this.setState({ latitudeFocus: true })}
                        onBlur={(e) => this.setState({ latitudeFocus: false })}
                      />
                      {this.state.firstnameState === "has-danger" ? (
                        <label className="error">This field is required.</label>
                      ) : null}
                    </InputGroup>
                  </Col>
                  <Col lg="6" md="6">
                    <InputGroup
                      className={classnames(this.state.longitudeState, {
                        "input-group-focus": this.state.longitudeFocus,
                      })}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <BsGeoAlt />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        name="longitude"
                        placeholder="Longitude"
                        type="text"
                        onChange={(e) =>
                          this.setState({ longitude: e.target.value })
                        }
                        onFocus={(e) => this.setState({ longitudeFocus: true })}
                        onBlur={(e) => this.setState({ longitudeFocus: false })}
                      />
                      {this.state.firstnameState === "has-danger" ? (
                        <label className="error">This field is required.</label>
                      ) : null}
                    </InputGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col lg="4" md="6">
            <Card>
              <CardHeader>
                <CardTitle tag="h5">Social Media</CardTitle>
              </CardHeader>
              <CardBody>
                <InputGroup
                  className={classnames(this.state.facebookState, {
                    "input-group-focus": this.state.facebookFocus,
                  })}
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      {/* <i className="tim-icons icon-single-02" /> */}
                      <FaFacebookF />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    name="facebook"
                    placeholder="Facebook"
                    type="text"
                    disabled={this.state.package === 'Free' || this.state.package === '' || this.state.package === 'Basic'}
                    onChange={(e) =>
                      this.setState({ facebook: e.target.value })
                    }
                    onFocus={(e) => this.setState({ facebookFocus: true })}
                    onBlur={(e) => this.setState({ facebookFocus: false })}
                  />
                  {this.state.firstnameState === "has-danger" ? (
                    <label className="error">This field is required.</label>
                  ) : null}
                </InputGroup>
                <InputGroup
                  className={classnames(this.state.instagramState, {
                    "input-group-focus": this.state.instagramFocus,
                  })}
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <FaInstagram />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    name="instagram"
                    placeholder="Instagram"
                    type="text"
                    disabled={this.state.package === 'Free' || this.state.package === '' || this.state.package === 'Basic'}

                    onChange={(e) =>
                      this.setState({ instagram: e.target.value })
                    }
                    onFocus={(e) => this.setState({ instagramFocus: true })}
                    onBlur={(e) => this.setState({ instagramFocus: false })}
                  />
                  {this.state.firstnameState === "has-danger" ? (
                    <label className="error">This field is required.</label>
                  ) : null}
                </InputGroup>
                <InputGroup
                  className={classnames(this.state.twitterState, {
                    "input-group-focus": this.state.twitterFocus,
                  })}
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <FaTwitter />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    name="twitter"
                    placeholder="Twitter"
                    type="text"
                    disabled={this.state.package === 'Free' || this.state.package === '' || this.state.package === 'Basic'}
                    onChange={(e) => this.setState({ twitter: e.target.value })}
                    onFocus={(e) => this.setState({ twitterFocus: true })}
                    onBlur={(e) => this.setState({ twitterFocus: false })}
                  />
                  {this.state.firstnameState === "has-danger" ? (
                    <label className="error">This field is required.</label>
                  ) : null}
                </InputGroup>
                <InputGroup
                  className={classnames(this.state.youtubeState, {
                    "input-group-focus": this.state.youtubeFocus,
                  })}
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <FaYoutube />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    name="youtube"
                    placeholder="Youtube"
                    type="text"
                    disabled={this.state.package === 'Free' || this.state.package === '' || this.state.package === 'Basic'}
                    onChange={(e) => this.setState({ youtube: e.target.value })}
                    onFocus={(e) => this.setState({ youtubeFocus: true })}
                    onBlur={(e) => this.setState({ youtubeFocus: false })}
                  />
                  {this.state.firstnameState === "has-danger" ? (
                    <label className="error">This field is required.</label>
                  ) : null}
                </InputGroup>
              </CardBody>
            </Card>
          </Col>

          <Col lg="4" md="6">
            <Card style={{ height: 267 }}>
              <CardHeader>
                <CardTitle tag="h5">Other Information</CardTitle>
              </CardHeader>
              <CardBody>
                <InputGroup
                  className={classnames(this.state.websiteState, {
                    "input-group-focus": this.state.websiteFocus,
                  })}
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <MdLocalMovies />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    name="video"
                    placeholder="Youtube Video Id"
                    type="text"
                    disabled={this.state.package === 'Free' || this.state.package === '' || this.state.package === 'Basic'}
                    onChange={(e) => this.setState({ video: e.target.value })}
                    onFocus={(e) => this.setState({ firstnameFocus: true })}
                    onBlur={(e) => this.setState({ firstnameFocus: false })}
                  />
                  {this.state.firstnameState === "has-danger" ? (
                    <label className="error">This field is required.</label>
                  ) : null}
                </InputGroup>
                <InputGroup
                  className={classnames(this.state.websiteState, {
                    "input-group-focus": this.state.websiteFocus,
                  })}
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <FaGlobeAmericas />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    name="website"
                    placeholder="Website"
                    disabled={this.state.package != 'Premium' && this.state.package != 'Commission'}
                    type="text"
                    onChange={(e) => this.setState({ website: e.target.value })}
                    onFocus={(e) => this.setState({ websiteFocus: true })}
                    onBlur={(e) => this.setState({ websiteFocus: false })}
                  />
                  {this.state.firstnameState === "has-danger" ? (
                    <label className="error">This field is required.</label>
                  ) : null}
                </InputGroup>

              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg={this.state.package === 'Commission' ? '6' : '12'} md={this.state.package === 'Commission' ? '6' : '12'}>
            <Card style={{ minHeight: 267 }}>
              <CardHeader>
                <CardTitle tag="h5">Opening Hours</CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  {this.state.openingHours.map((time, i) => (
                    <Col md={this.state.package === 'Commission' ? '12' : '6'}>
                      <Row style={{ marginBottom: 20 }}>
                        <Col md={4}>
                          <Select
                            className="react-select info"
                            classNamePrefix="react-select"
                            name="openingDays"
                            // value={this.state.category}
                            onChange={(e) => {
                              this.state.openingHours[i].name = e.value;
                              this.setState({
                                openingHours: [...this.state.openingHours],
                              });
                            }}
                            options={openingDays}
                            placeholder="Select Day"
                          />
                        </Col>
                        <Col md={4}>
                          <Select
                            className="react-select info"
                            classNamePrefix="react-select"
                            name="openingTime"
                            // value={this.state.category}
                            onChange={(e) => {
                              this.state.openingHours[i].open = e.value;
                              this.setState({
                                openingHours: [...this.state.openingHours],
                              });
                            }}
                            options={openingTime}
                            placeholder="Opening Time"
                          />
                        </Col>
                        <Col md={4}>
                          <Select
                            className="react-select info"
                            classNamePrefix="react-select"
                            name="Closing"
                            // value={this.state.category}
                            onChange={(e) => {
                              this.state.openingHours[i].close = e.value;
                              this.setState({
                                openingHours: [...this.state.openingHours],
                              });
                            }}
                            options={openingTime}
                            placeholder="Closing Time"
                          />
                        </Col>
                      </Row>
                    </Col>

                  ))}

                </Row>
                <div>
                  <Button onClick={this.addTime} disabled={this.state.package != 'Premium' && this.state.package != 'Commission'}>Add New Opening Hours</Button>
                </div>
              </CardBody>
            </Card>
          </Col>
          {this.state.package === 'Commission' &&
            (<Col md={this.state.package === 'Commission' ? '6' : '12'}>
              <Card style={{ minHeight: 267 }}>
                <CardHeader>
                  <CardTitle tag="h5">Pricing Details</CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                    {this.state.pricing.map((time, i) => (

                      <Col md='12'>
                        <Row style={{ marginBottom: 20 }}>


                          <Col md={12}>


                            <InputGroup
                              className={classnames(this.state.pricingNameState, {
                                "input-group-focus": this.state.pricingNameFocus,
                              })}
                            >
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <FaTags />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                name="pricingName"
                                placeholder="Pricing Name"
                                type="text"
                                onChange={(e) => {
                                  this.state.pricing[i].name = e.target.value;
                                  this.setState({
                                    pricing: [...this.state.pricing],
                                  });
                                }}
                                onFocus={(e) => this.setState({ pricingNameFocus: true })}
                                onBlur={(e) => this.setState({ pricingNameFocus: false })}
                              />
                              {this.state.pricingNameState === "has-danger" ? (
                                <label className="error">This field is required.</label>
                              ) : null}
                            </InputGroup>
                          </Col>




                          <Col md="12">
                            <p className="category">Is a Package</p>
                            <Switch
                              defaultValue={this.state.pricing[i].isPackage || false}
                              offColor=""
                              onColor=""
                              offText="No"
                              onText="Yes"
                              onChange={(value) => {
                                this.state.pricing[i].isPackage = value.state.value;
                                this.setState({
                                  pricing: [...this.state.pricing],
                                });
                              }}
                            />
                          </Col>

                          {this.state.pricing[i].isPackage &&
                            <>
                              <Col md={12}>
                                <InputGroup
                                  className={classnames(this.state.pricingState, {
                                    "input-group-focus": this.state.pricingFocus,
                                  })}
                                >
                                  <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                      <FaDollarSign />
                                    </InputGroupText>
                                  </InputGroupAddon>
                                  <Input
                                    name="pricingAdult"
                                    placeholder="Package Cost"
                                    type="number"
                                    onChange={(e) => {
                                      this.state.pricing[i].cost = e.target.value;
                                      this.setState({
                                        pricing: [...this.state.pricing],
                                      });
                                    }}
                                    onFocus={(e) => this.setState({ pricingFocus: true })}
                                    onBlur={(e) => this.setState({ pricingFocus: false })}
                                  />
                                  {this.state.pricingAdultState === "has-danger" ? (
                                    <label className="error">This field is required.</label>
                                  ) : null}
                                </InputGroup>
                              </Col>



                            </>}

                          {this.state.pricing[i].isPackage &&
                            <>
                              <Col md={6}>
                                <InputGroup
                                  className={classnames(this.state.adultQuantityState, {
                                    "input-group-focus": this.state.adultQuantityFocus,
                                  })}
                                >
                                  <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                      {/* <FaDollarSign /> */}
                                    </InputGroupText>
                                  </InputGroupAddon>
                                  <Input
                                    name="packageAdultd"
                                    placeholder="Adult Quantity"
                                    type="number"
                                    onChange={(e) => {
                                      this.state.pricing[i].adultQuantity = e.target.value;
                                      this.setState({
                                        pricing: [...this.state.pricing],
                                      });
                                    }}
                                    onFocus={(e) => this.setState({ adultQuantityFocus: true })}
                                    onBlur={(e) => this.setState({ adultQuantityFocus: false })}
                                  />
                                  {this.state.pricingAdultState === "has-danger" ? (
                                    <label className="error">This field is required.</label>
                                  ) : null}
                                </InputGroup>
                              </Col>
                              <Col md={6}>
                                <InputGroup
                                  className={classnames(this.state.childQunatityState, {
                                    "input-group-focus": this.state.childQunatityFocus,
                                  })}
                                >
                                  <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                      {/* <FaDollarSign /> */}
                                    </InputGroupText>
                                  </InputGroupAddon>
                                  <Input
                                    name="packageChild"
                                    placeholder="Children Quantity"
                                    type="number"
                                    onChange={(e) => {
                                      this.state.pricing[i].childQunatity = e.target.value;
                                      this.setState({
                                        pricing: [...this.state.pricing],
                                      });
                                    }}
                                    onFocus={(e) => this.setState({ childQunatityFocus: true })}
                                    onBlur={(e) => this.setState({ childQunatityFocus: false })}
                                  />
                                  {this.state.pricingAdultState === "has-danger" ? (
                                    <label className="error">This field is required.</label>
                                  ) : null}
                                </InputGroup>
                              </Col>
                            </>}

                          {!this.state.pricing[i].isPackage &&
                            <>
                              <Col md={6}>
                                <InputGroup
                                  className={classnames(this.state.pricingAdultState, {
                                    "input-group-focus": this.state.pricingAdultFocus,
                                  })}
                                >
                                  <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                      <FaDollarSign />
                                    </InputGroupText>
                                  </InputGroupAddon>
                                  <Input
                                    name="pricingAdult"
                                    placeholder="Adult Price"
                                    type="number"
                                    onChange={(e) => {
                                      this.state.pricing[i].adult = e.target.value;
                                      this.setState({
                                        pricing: [...this.state.pricing],
                                      });
                                    }}
                                    onFocus={(e) => this.setState({ pricingAdultFocus: true })}
                                    onBlur={(e) => this.setState({ pricingAdultFocus: false })}
                                  />
                                  {this.state.pricingAdultState === "has-danger" ? (
                                    <label className="error">This field is required.</label>
                                  ) : null}
                                </InputGroup>
                              </Col>
                              <Col md={6}>
                                <InputGroup
                                  className={classnames(this.state.pricingChildState, {
                                    "input-group-focus": this.state.pricingChildFocus,
                                  })}
                                >
                                  <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                      <FaDollarSign />
                                    </InputGroupText>
                                  </InputGroupAddon>
                                  <Input
                                    name="pricingAdult"
                                    placeholder="Child Price"
                                    type="number"
                                    onChange={(e) => {
                                      this.state.pricing[i].child = e.target.value;
                                      this.setState({
                                        pricing: [...this.state.pricing],
                                      });
                                    }}
                                    onFocus={(e) => this.setState({ pricingChildFocus: true })}
                                    onBlur={(e) => this.setState({ pricingChildFocus: false })}
                                  />
                                  {this.state.pricingAdultState === "has-danger" ? (
                                    <label className="error">This field is required.</label>
                                  ) : null}
                                </InputGroup>
                              </Col>
                            </>}
                          {/* <Col md={6}>
                            <InputGroup
                              className={classnames(this.state.pricingChildState, {
                                "input-group-focus": this.state.pricingChildFocus,
                              })}
                            >
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <FaDollarSign />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                name="pricingChild"
                                placeholder="Child Price"
                                type="number"
                                onChange={(e) => {
                                  this.state.pricing[i].child = e.target.value;
                                  this.setState({
                                    pricing: [...this.state.pricing],
                                  });
                                }}
                                onFocus={(e) => this.setState({ pricingChildFocus: true })}
                                onBlur={(e) => this.setState({ pricingChildFocus: false })}
                              />
                              {this.state.pricingChildState === "has-danger" ? (
                                <label className="error">This field is required.</label>
                              ) : null}
                            </InputGroup>
                          </Col> */}

                          <Col md="12">
                            <Input
                              type="textarea"
                              id="details"
                              defaultValue={this.state.pricing[i].details}
                              onChange={(e) => {
                                this.state.pricing[i].details = e.target.value;
                                this.setState({
                                  pricing: [...this.state.pricing],
                                });
                              }
                                // this.setState({ priceDescription: e.target.value })
                              }
                              placeholder="Description"
                              style={{ padding: 10, height: 192, maxHeight: 192 }}
                            />
                          </Col>
                          <Col md={12}>
                            <Button style={{ width: '100%' }} onClick={() => this.removePackage(i)}>Remove Package</Button>
                          </Col>
                        </Row>
                      </Col>

                    ))}

                  </Row>
                  <div>
                    <Button onClick={this.addPricing}>Add New Pricing</Button>
                  </div>
                </CardBody>
              </Card>
            </Col>)
          }
          <Col>
          </Col>
        </Row>
        <Row>
          <Col lg="12" md="12">
            <Card style={{ minHeight: 267 }}>
              <CardHeader>
                <CardTitle tag="h5">
                  Gallery Images ({this.state.images.length})
                </CardTitle>
              </CardHeader>
              <CardBody>
                <Row>

                  <Col md={12}>
                    {/* {i + 1}
                      <ImageUpload
                        key={i + 1}
                        setImage={this.setImage}
                        active={this.state.imageActive}
                        name={this.state.name}
                        type={"Gallery"}
                        index={i + 1}
                        removeImage={this.removeImage}
                      /> */}
                    <MultipleUpload destination={this.state.name} images={this.state.images} setImages={this.setImage} pack={this.state.package} />
                  </Col>



                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col
            lg={6}
            className="ml-auto mr-auto"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Button color="success" onClick={(e) => this.setDestinations(e)}>
              Add Destination
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

export default AddDestination;
