import React from "react";
import { Card, CardHeader, CardBody, CardTitle, Row, Col } from "reactstrap";
import { Line } from "react-chartjs-2";
import { chartExample1 } from "../../variables/charts.jsx";

class PerformanceChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bigChartData: "data1",
      labels: [
        "JAN",
        "FEB",
        "MAR",
        "APR",
        "MAY",
        "JUN",
        "JUL",
        "AUG",
        "SEP",
        "OCT",
        "NOV",
        "DEC",
      ],
      jan: 0,
      feb: 0,
      mar: 0,
      apr: 0,
      may: 0,
      jun: 0,
      jul: 0,
      aug: 0,
      sep: 0,
      oct: 0,
      nov: 0,
      dec: 0,
    };
  }

  componentDidMount() {
    this.setData();
  }
//   componentWillUpdate() {
//     this.setData();
//   }

  setData = () => {
    // 
    var jan = 0;
    var  feb = 0;
    var  mar =  0;
    var  apr = 0;
    var  may = 0;
    var  jun = 0;
    var  jul = 0;
    var  aug = 0;
    var  sep = 0;
    var  oct = 0;
    var  nov = 0;
    var  dec = 0;
    this.props.data.map((doc) => {
     
      switch (
        doc.data().dateAdded.toDate().getMonth()
      ) {
        case 0:
            jan++;
            this.setState({jan})
          break;
        case 1:
            feb++;
            this.setState({feb})
          break;
        case 2:
            mar++;
            this.setState({mar})
          break;
        case 3:
            apr++;
            this.setState({apr})
          break;
        case 4:
            may++;
            this.setState({may})
          break;
        case 5:
            jun++;
            this.setState({jun})
          break;
        case 6:
            jul++;
            this.setState({jul})
          break;
        case 7:
            aug++;
            this.setState({aug})
          break;
        case 8:
            sep++;
            this.setState({sep})
          break;
        case 9:
            oct++;
            this.setState({oct})
          break;
        case 10:
            nov++;
            this.setState({nov})
          break;
        case 11:
            dec++;
            this.setState({dec})
          break;
        default:
          break;
      }
    });
  };

  setBgChartData = (name) => {
    this.setState({
      bigChartData: name,
    });
  };
  chartData = {
    data1: (canvas) => {
      let ctx = canvas.getContext("2d");

      let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
      gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
      gradientStroke.addColorStop(0, "rgba(29,140,248,0)"); //blue colors

      return {
        labels: this.state.labels,
        datasets: [
          {
            label: "",
            fill: true,
            backgroundColor: gradientStroke,
            borderColor: "#1f8ef1",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: "#1f8ef1",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#1f8ef1",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: [
              this.state.jan,
              this.state.feb,
              this.state.mar,
              this.state.apr,
              this.state.may,
              this.state.jun,
              this.state.jul,
              this.state.aug,
              this.state.sep,
              this.state.oct,
              this.state.nov,
              this.state.dec,
            ],
          },
        ],
      };
    },

    options: this.chart_1_2_3_options,
  };

  chart_1_2_3_options = {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    tooltips: {
      backgroundColor: "#f5f5f5",
      titleFontColor: "#333",
      bodyFontColor: "#666",
      bodySpacing: 4,
      xPadding: 12,
      mode: "nearest",
      intersect: 0,
      position: "nearest",
    },
    responsive: true,
    scales: {
      yAxes: [
        {
          barPercentage: 1.6,
          gridLines: {
            drawBorder: false,
            color: "rgba(29,140,248,0.0)",
            zeroLineColor: "transparent",
          },
          ticks: {
            suggestedMin: 60,
            suggestedMax: 125,
            padding: 20,
            fontColor: "#9a9a9a",
          },
        },
      ],
      xAxes: [
        {
          barPercentage: 1.6,
          gridLines: {
            drawBorder: false,
            color: "rgba(29,140,248,0.1)",
            zeroLineColor: "transparent",
          },
          ticks: {
            padding: 20,
            fontColor: "#9a9a9a",
          },
        },
      ],
    },
  };

  render() {
      if(!this.props.data){
          return null;
      }
    return (
      <Card className="card-chart">
        <CardHeader>
          <Row>
            <Col className="text-left" sm="6">
              <h5 className="card-category">Total Destinations Added</h5>
              <CardTitle tag="h2">
                Performance
                {/* {new Date().getFullYear()} */}
              </CardTitle>
            </Col>
            <Col sm="6">
              {/* <ButtonGroup
                className="btn-group-toggle float-right"
                data-toggle="buttons"
              >
                <Button
                  color="info"
                  id="0"
                  size="sm"
                  tag="label"
                  className={classNames("btn-simple", {
                    active: this.state.bigChartData === "data1",
                  })}
                  onClick={() => this.setBgChartData("data1")}
                >
                  <input defaultChecked name="options" type="radio" />
                  <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                    Total
                  </span>
                  <span className="d-block d-sm-none">
                    <i className="tim-icons icon-single-02" />
                  </span>
                </Button>
                <Button
                  color="info"
                  id="2"
                  size="sm"
                  tag="label"
                  className={classNames("btn-simple", {
                    active: this.state.bigChartData === "data2",
                  })}
                  onClick={() => this.setBgChartData("data2")}
                >
                  <input name="options" type="radio" />
                  <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                    Free
                  </span>
                  <span className="d-block d-sm-none">
                    <i className="tim-icons icon-tap-02" />
                  </span>
                </Button>

                <Button
                  color="info"
                  id="1"
                  size="sm"
                  tag="label"
                  className={classNames("btn-simple", {
                    active: this.state.bigChartData === "data3",
                  })}
                  onClick={() => this.setBgChartData("data3")}
                >
                  <input name="options" type="radio" />
                  <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                    Basic
                  </span>
                  <span className="d-block d-sm-none">
                    <i className="tim-icons icon-gift-2" />
                  </span>
                </Button>
                <Button
                  color="info"
                  id="0"
                  size="sm"
                  tag="label"
                  className={classNames("btn-simple", {
                    active: this.state.bigChartData === "data4",
                  })}
                  onClick={() => this.setBgChartData("data4")}
                >
                  <input defaultChecked name="options" type="radio" />
                  <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                    Standard
                  </span>
                  <span className="d-block d-sm-none">
                    <i className="tim-icons icon-single-02" />
                  </span>
                </Button>
                <Button
                  color="info"
                  id="2"
                  size="sm"
                  tag="label"
                  className={classNames("btn-simple", {
                    active: this.state.bigChartData === "data5",
                  })}
                  onClick={() => this.setBgChartData("data5")}
                >
                  <input name="options" type="radio" />
                  <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                    Premium
                  </span>
                  <span className="d-block d-sm-none">
                    <i className="tim-icons icon-tap-02" />
                  </span>
                </Button>
              </ButtonGroup> */}
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <div className="chart-area">
            <Line
              data={this.chartData[this.state.bigChartData]}
              options={chartExample1.options}
            />
          </div>
        </CardBody>
      </Card>
    );
  }
}

export default PerformanceChart;
