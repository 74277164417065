import React, { Component } from "react";
import classNames from "classnames";
// react component for creating dynamic tables
import ReactTable from "react-table";
import {
  Card,
  CardBody,
  CardHeader,
  Row,
  Col,
  UncontrolledTooltip,
  FormGroup,
  Input,
  Form,
  Button,
} from "reactstrap";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { connect } from 'react-redux'
import { firestore } from "../utils/firebase";
import { collection, addDoc, deleteDoc, doc, Timestamp, updateDoc } from 'firebase/firestore'
import NotificationAlert from "react-notification-alert";

class GiftCertificates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      company: '',
      value: 0,
      amount: 0,
      data: this.props.certs?.map((doc) => {
        return {
          id: doc.id,
          code: doc.data().code,
          company: doc.data().company,
          value: doc.data().value,
          creationDate: doc.data().createdDate.toDate().toDateString(),
          status: (<p style={{ color: doc.data().status === 'Created' ? 'green' : 'red' }}>{doc.data().status}</p>),
          redeemby: doc.data().redeemedBy,
          redeemDate: doc.data()?.redeemDate?.toDate().toDateString(),
          actions: (
            // we've added some custom button actions
            <div className="actions-right" key={doc.id}>

              {/* use this button to add a edit kind of action */}
              {/* <Link
                      className="btn-link btn-icon btn-success"
                      to='/admin/edit_event'
                      color="success"
                      id={`tooltip${doc.id}`}
                      size="sm"
                      title="Renew"
                      type="button"
                      onClick={()=>this.props.selectEvent(doc)}
                    > */}
              <i className="tim-icons icon-pencil" />
              {/* </Link>{" "} */}

            </div>
          )
        };
      })
    };
  }


  createCertificates = async () => {

    let temp = this.props.certNumber.data().code 

    for (let i = 0; i < this.state.amount; i++) {
      await addDoc(collection(firestore, "gift-certs"), {
        code: temp,
        company: this.state.company,
        createdDate: Timestamp.fromDate(new Date()),
        expiryDate: Timestamp.fromDate(new Date(new Date().setFullYear(new Date().getFullYear() + 1))),
        status: 'Created',
        redeemedBy: '',
        value: this.state.value
      })
      temp++
    }

    await updateDoc(doc(firestore, 'cert-number', this.props.certNumber.id), {
      code: temp
    })
    this.hideAlert();
    this.setState({
      data: this.props.certs?.map((doc) => {
        return {
          id: doc.id,
          code: doc.data().code,
          company: doc.data().company,
          value: doc.data().value,
          creationDate: doc.data().createdDate.toDate().toDateString(),
          status: (<p style={{ color: doc.data().status === 'Created' ? 'green' : 'red' }}>{doc.data().status}</p>),
          redeemby: doc.data().redeemedBy,
          redeemDate: doc.data()?.redeemDate?.toDate().toDateString(),
          actions: (
            // we've added some custom button actions
            <div className="actions-right" key={doc.id}>

              {/* use this button to add a edit kind of action */}
              {/* <Link
                className="btn-link btn-icon btn-success"
                to='/admin/edit_event'
                color="success"
                id={`tooltip${doc.id}`}
                size="sm"
                title="Renew"
                type="button"
                onClick={()=>this.props.selectEvent(doc)}
              > */}
              <i className="tim-icons icon-pencil" />
              {/* </Link>{" "} */}

            </div>
          )
        };
      })
    }
    )
  };



  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };


  htmlAlert = () => {
    this.setState({
      alert: (
        <ReactBSAlert
          style={{ display: "block", marginTop: "-300px" }}
          title="Add Gift Certificate"
          onConfirm={() => this.createCertificates()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          btnSize=""
          showCancel
          confirmBtnText="Add Gift Certificate!"
          cancelBtnText="Cancel"
        >
          <Form action="#">

            <FormGroup>
              <Input
                placeholder="Company Name"
                onChange={(e) => this.setState({ company: e.target.value })}
                style={{ color: "grey" }}
              />
            </FormGroup>

            <FormGroup>
              <Input
                placeholder="Certificate value"
                onChange={(e) => this.setState({ value: parseFloat(e.target.value) })}
                style={{ color: "grey" }}
              />
            </FormGroup>
            <FormGroup>
              <Input
                placeholder="Number of Certificate"
                onChange={(e) => this.setState({ amount: parseInt(e.target.value) })}
                style={{ color: "grey" }}
              />
            </FormGroup>

          </Form>
        </ReactBSAlert>
      ),
    });
  };

  render() {


    return (
      <>
        <div className="content">
          {this.state.alert}
          <Col md={8} className="ml-auto mr-auto">
            <h2 className="text-center">All ({this.props.certs.length}) Gift Certificates</h2>

          </Col>
          <Row className="mt-5">
            <Col xs={12} md={12}>
              <Card>
                <CardHeader>
                  {/* <CardTitle tag="h4">React Table</CardTitle> */}
                  <Button
                    color="success"
                    caret
                    id="tooltip342"
                    title=""
                    className="btn-link btn-icon"
                    type="button"
                    style={{ float: "right" }}
                    onClick={(e) => this.htmlAlert()}
                  >
                    <i className="tim-icons icon-simple-add" />
                  </Button>

                  <UncontrolledTooltip delay={0} target="tooltip342">
                    Create Certificates
                  </UncontrolledTooltip>
                </CardHeader>
                <CardBody>
                  <ReactTable
                    data={this.state.data}
                    filterable
                    resizable={false}
                    columns={[
                      {
                        Header: "Serial Number",
                        accessor: "code"
                      },
                      {
                        Header: "Company",
                        accessor: "company"
                      },
                      {
                        Header: "Creation Date",
                        accessor: "creationDate"
                      },
                      {
                        Header: "Redeemed By",
                        accessor: "redeemby",
                        filterable: true
                      },
                      {
                        Header: 'Redeemed Date',
                        accessor: 'redeemDate',
                        sortable: false,
                        filterable: false
                      },
                      {
                        Header: 'Value',
                        accessor: 'value',
                        sortable: false,
                        filterable: false
                      },
                      {
                        Header: 'Status',
                        accessor: 'status',
                        sortable: false,
                        filterable: false
                      },
                      {
                        Header: "Actions",
                        accessor: "actions",
                        sortable: false,
                        filterable: false
                      }
                    ]}
                    defaultPageSize={10}
                    showPaginationTop
                    showPaginationBottom={false}
                    className="-striped -highlight"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }



}



const mapStateToProps = (state) => {


  return {
    certs: state.certs,
    certNumber: state.certNum
  }
}

export default connect(mapStateToProps, {})(GiftCertificates);
