import React from "react";
import { connect } from "react-redux";
import { firestore } from "../../utils/firebase";
import ReactBSAlert from "react-bootstrap-sweetalert";
import NotificationAlert from "react-notification-alert";
import moment from "moment";
import { addDoc, updateDoc, collection, doc, Timestamp } from 'firebase/firestore'

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Table,
  Form,
  UncontrolledTooltip,
} from "reactstrap";
import { Component } from "react";
import { async } from "@firebase/util";

class TaskCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      name: "",
      description: "",
      visible: true,

    };
  }

  createTask = async () => {
    await addDoc(collection(firestore, "tasks"), {
      title: this.state.name,
      description: this.state.description,
      status: "Created",
      dateCreated: Timestamp.fromDate(new Date()),
      dateUpdated: Timestamp.fromDate(new Date()),
    })
      .then((doc) => {
        this.hideAlert();
        this.notify("bl", "The New Task was successfully created");
      });
  };


  markComplete = async(id) => {

    await updateDoc(doc(firestore, 'tasks', id), {
      status: "Completed",
      dateUpdated: Timestamp.fromDate(new Date()),
    }).then((doc) => {
        this.successUpdate()
      });
  };


  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };
  notify = (place, message) => {
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>{message}</div>
        </div>
      ),
      type: "success",
      icon: "tim-icons icon-bell-55",
      autoDismiss: 7,
    };
    this.refs.notificationAlert.notificationAlert(options);
  };

  render() {

    if (!this.props.tasks) {
      return null;
    }

    return (
      <Card className="card-tasks">
        {this.state.alert}
        <NotificationAlert ref="notificationAlert" />
        <CardHeader>
          <h6 className="title d-inline">Tasks({this.props.tasks.length})</h6>
          <p className="card-category d-inline">today</p>
          <Button
            color="success"
            caret
            id="tooltip342"
            title=""
            className="btn-link btn-icon"
            type="button"
            style={{ float: "right" }}
            onClick={(e) => this.htmlAlert()}
          >
            <i className="tim-icons icon-simple-add" />
          </Button>
          <UncontrolledTooltip delay={0} target="tooltip342">
            Create Task
          </UncontrolledTooltip>
        </CardHeader>
        <CardBody>
          <div className="table-full-width table-responsive">
            <Table>
              <tbody>
                {this.props.tasks.map((task) => {
                  return (
                    <tr key={task.id}>
                      {/* <td>
                        <FormGroup check>
                          <Label check>
                            <Input
                              defaultValue=""
                              type="checkbox"
                              id={task.id}
                              onChange={()=>}
                            />
                            <span className="form-check-sign">
                              <span className="check" />
                            </span>
                          </Label>
                        </FormGroup>
                      </td> */}
                      <td>
                        <h6 className="title">{task.data().title}</h6>
                        <p>{task.data().description}</p>
                        {task.data().dateUpdated && <p className="text-muted">
                          {task.data().status} -{" "}
                          {moment
                            .utc(task.data().dateUpdated.toDate())
                            .local()
                            .startOf("seconds")
                            .fromNow()}
                        </p>}
                      </td>
                      <td className="td-actions text-right">
                        <Button
                          color="link"
                          id={`tooltip${task.id}`}
                          title=""
                          type="button"
                          onClick={() => this.warningWithConfirmAndCancelMessage(task.id)}
                        >
                          <i className="tim-icons icon-check-2" />
                        </Button>
                        <UncontrolledTooltip
                          delay={0}
                          target={`tooltip${task.id}`}
                        >
                          Mark as Complete
                        </UncontrolledTooltip>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
    );

  }

  htmlAlert = () => {
    this.setState({
      alert: (
        <ReactBSAlert
          style={{ display: "block", marginTop: "-200px" }}
          title="CREATE A TASK"
          onConfirm={() => this.createTask()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          btnSize=""
          showCancel
          confirmBtnText="Create Task!"
          cancelBtnText="Cancel"
        >
          <Form action="#">
            <FormGroup>
              <Input
                placeholder="Title"
                onChange={(e) => this.setState({ name: e.target.value })}
                style={{ color: "grey" }}
              />
            </FormGroup>
            <FormGroup>
              <Input
                type="textarea"
                name="text"
                id="exampleText"
                onChange={(e) => this.setState({ description: e.target.value })}
                placeholder="Description"
                style={{ color: "grey" }}
              />
            </FormGroup>
          </Form>
        </ReactBSAlert>
      ),
    });
  };


  warningWithConfirmAndCancelMessage = (id) => {
    this.setState({
      alert: (
        <ReactBSAlert
          warning
          style={{ display: "block", marginTop: "-150px" }}
          title="Are you sure?"
          onConfirm={() => this.markComplete(id)}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, Update it!"
          cancelBtnText="Cancel"
          showCancel
          btnSize=""
        >
          You will not be able to change the status of this tasks!
        </ReactBSAlert>
      )
    });
  };

  successUpdate = () => {
    this.setState({
      alert: (
        <ReactBSAlert
          success
          style={{ display: "block", marginTop: "-150px" }}
          title="COMPLETED!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          btnSize=""
        >
          Your Task has been updated.
        </ReactBSAlert>
      )
    });
  };
}
const mapStateToProps = (state) => {
  return {
    tasks: state.tasks,
    tasksLoaded: state.tasksLoaded,
  };
};
export default connect(
  mapStateToProps,
  {}
)(TaskCard);
