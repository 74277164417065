import firebase, { firestore } from "../../utils/firebase";
import { collection, getDocs, onSnapshot, orderBy, query, where } from 'firebase/firestore';


export const getTasks = () => async dispatch => {

  const coll = collection(firestore, 'tasks')
  const q = query(coll, orderBy('dateCreated'))
  onSnapshot(q, (querySnapshot) => {
    dispatch({
      type: 'GET_TASKS',
      payload: querySnapshot.docs
    })
  })
}


export const getCerts = () => async dispatch => {

  const coll = collection(firestore, 'gift-certs')
  const q = query(coll, orderBy('code'))
  onSnapshot(q, (querySnapshot) => {
    dispatch({
      type: 'GET_CERTS',
      payload: querySnapshot.docs
    })
  })
}

export const getCertNumber =() => async dispatch => {
  const coll = collection(firestore, 'cert-number')
  const q = query(coll)
  onSnapshot(coll, (querySnapshot) => {
    dispatch({
      type: 'GET_CERT_NUMBER',
      payload: querySnapshot.docs[0]
    })
  })
}

// Action creator
export const getDestinations = () => async dispatch => {

  const coll = collection(firestore, 'places')
  const q = query(coll, orderBy('dateAdded', 'asc'))
  const data = await getDocs(coll)

    dispatch({
      type: 'GET_DESTINATIONS',
      payload: data.docs
    })

}

export const getBookings = () => async dispatch => {

  const coll = collection(firestore, 'bookings')
  onSnapshot(coll, (querySnapshot) => {
    dispatch({
      type: 'GET_BOOKINGS',
      payload: querySnapshot.docs
    })
  })
}




export const getEvents = () => async dispatch => {

  const coll = collection(firestore, 'events')
  onSnapshot(coll, (querySnapshot) => {
    dispatch({
      type: 'GET_EVENTS',
      payload: querySnapshot.docs
    })
  })



}

export const getSponsors = () => async dispatch => {

  const coll = collection(firestore, 'sponsors')
  onSnapshot(coll, (querySnapshot) => {
    dispatch({
      type: 'GET_SPONSORS',
      payload: querySnapshot.docs
    })
  })




}

export const getAds = () => async dispatch => {

  const coll = collection(firestore, 'ads')
  onSnapshot(coll, (querySnapshot) => {
    dispatch({
      type: 'GET_ADS',
      payload: querySnapshot.docs
    })
  })
}


export const getConsultants = () => async dispatch => {
  const coll = collection(firestore, 'salesConsultants')
  onSnapshot(coll, (querySnapshot) => {
    dispatch({
      type: 'GET_CONSULTANTS',
      payload: querySnapshot.docs
    })
  })

}

export const getCommissionUsers = () => async dispatch => {
  const coll = collection(firestore, 'commissionUsers')
  onSnapshot(coll, (querySnapshot) => {
    dispatch({
      type: 'GET_COMMISSION_USERS',
      payload: querySnapshot.docs
    })
  })

}

export const getUsers = () => async dispatch => {
  const coll = collection(firestore, 'admin_users')
  onSnapshot(coll, (querySnapshot) => {
    dispatch({
      type: 'GET_USERS',
      payload: querySnapshot.docs
    })
  })



}



export const getContactPerson = () => async dispatch => {

  const coll = collection(firestore, 'contact_person')
  onSnapshot(coll, (querySnapshot)=>{
    dispatch({
      type: 'GET_CONTACT_PERSON',
      payload: querySnapshot.docs,
    })
  })


 
}

export const selectDestination = destination => {

  // Return an action
  return {
    type: 'DESTINATION_SELECTED',
    payload: destination
  };
};




export const getCategories = () => async dispatch => {

  const coll = collection(firestore, 'categories')
  onSnapshot(coll, (querySnapshot)=>{
    dispatch({
      type: 'GET_CATEGORIES',
      payload: querySnapshot.docs,
    })
  })


  

}

export const setAuthenticated = bool => {
  return {
    type: 'SET_AUTHENTICATED',
    payload: bool,
  }
}

export const getPaymentRequests = () => async dispatch => {

  const coll = collection(firestore, 'paymentRequests')
  onSnapshot(coll, (querySnapshot)=>{
    dispatch({
      type: 'GET_PAYMENT_REQUESTS',
      payload: querySnapshot.docs
    })
  })

}

export const getCoupons = () =>async dispatch =>{
  const coll = collection(firestore, 'coupons')
  onSnapshot(coll, (querySnapshot)=>{
    dispatch({
      type: 'GET_COUPONS',
      payload: querySnapshot.docs
    })
  })
}