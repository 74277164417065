import React from "react";
import { connect } from "react-redux";
import firebase from "../utils/firebase";
import ReactBSAlert from "react-bootstrap-sweetalert";
import NotificationAlert from "react-notification-alert";
import moment from "moment";
import ReactTable from "react-table";
import { roles } from "../data/roles";
import Select from "react-select";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Form,
  UncontrolledTooltip,
} from "reactstrap";
import { Component } from "react";
import ImageUpload from "../components/CustomUpload/ImageUpload";

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      firstName: "",
      lastName: "",
      email: "",
      role: "",
      image: "",
      visible: true,
      data: this.props.users.map(doc => {
        return {
          id: doc.id,
          firstName: doc.data().first_name,
          lastName: doc.data().last_name,
          email: doc.data().email,
          role: doc.data().role,
          actions: (
            // we've added some custom button actions
            <div className="actions-right">

              {/* use this button to add a edit kind of action */}
              <Button
                className="btn-link btn-icon"
                color="success"
                size="sm"

              >
                <i className="tim-icons icon-pencil" />
              </Button>{" "}
              {/* use this button to remove the data row */}
              {/* <Button
                className="btn-link btn-icon"
                color="danger"
                size="sm"
                onClick={() => this.removeUser(doc.id)}
              >
                <i className="tim-icons icon-simple-remove" />
              </Button> */}
            </div>
          )
        };
      }),
    };
  }

  createUser = () => {
    firebase
      .firestore()
      .collection("admin_users")
      .add({
        first_name: this.state.firstName,
        last_name: this.state.lastName,
        email: this.state.email,
        role: this.state.role,
        image: this.state.image,
      })
      .then((doc) => {
        this.hideAlert();
        this.notify("bl", "The Sales Consultant was successfully created");
      });
  };

  removeConsultant = (id) => {
    firebase.firestore()
      .collection('Admin_Users')
      .doc(id)
      .delete()
      .then(() => {
        this.notify("bl", "The User was successfully REMOVED");
      })
  }

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  setImage = (url, type) => {
    this.state.image = url;
  };

  removeImage = () => {
    this.state.image = "";
  };

  notify = (place, message) => {
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>{message}</div>
        </div>
      ),
      type: "success",
      icon: "tim-icons icon-bell-55",
      autoDismiss: 7,
    };
    this.refs.notificationAlert.notificationAlert(options);
  };

  render() {
    // if (!this.props.consultants) {
    //   return null;
    // }

    return (
      <div className="content">
        <Card >
          {this.state.alert}
          <NotificationAlert ref="notificationAlert" />
          <CardHeader>
            <h6 className="title d-inline">Admin Users({this.props.users.length})</h6>
            {/* <p className="card-category d-inline">today</p> */}
            <Button
              color="success"
              caret
              id="tooltip342"
              title=""
              className="btn-link btn-icon"
              type="button"
              style={{ float: "right" }}
              onClick={(e) => this.htmlAlert()}
            >
              <i className="tim-icons icon-simple-add" />
            </Button>
            <UncontrolledTooltip delay={0} target="tooltip342">
              Add Consultant
            </UncontrolledTooltip>
          </CardHeader>
          <CardBody>
            <ReactTable
              data={this.state.data}
              filterable
              resizable={false}
              columns={[
                {
                  Header: "First Name",
                  accessor: "firstName"
                },
                {
                  Header: "Last Name",
                  accessor: "lastName"
                },
                {
                  Header: "Email Address",
                  accessor: "email"
                },
                {
                  Header: "User Role",
                  accessor: "role",
                  filterable: false
                },
                {
                  Header: "Actions",
                  accessor: "actions",
                  sortable: false,
                  filterable: false
                }
              ]}
              defaultPageSize={10}
              showPaginationTop
              showPaginationBottom={false}
              className="-striped -highlight"
            />
          </CardBody>
        </Card>
      </div>
    );
  }

  htmlAlert = () => {
    this.setState({
      alert: (
        <ReactBSAlert
          style={{ display: "block", marginTop: "-300px" }}
          title="Add An Admin User"
          onConfirm={() => this.createUser()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          btnSize=""
          showCancel
          confirmBtnText="Add User!"
          cancelBtnText="Cancel"
        >
          <Form action="#">
            <ImageUpload
              setImage={this.setImage}
              name={"Sales Consultant"}
              type={"Profile"}
              index={0}
              removeImage={this.removeImage}
            />

            <FormGroup>
              <Select
                className="react-select info"
                classNamePrefix="react-select"
                name="role"
                defaultValue={this.state.role}
                onChange={(e) => this.setState({ role: e.value })}
                options={roles}
                placeholder="Select Category"
              />
            </FormGroup>
            <FormGroup>
              <Input
                placeholder="First Name"
                onChange={(e) => this.setState({ firstName: e.target.value })}
                style={{ color: "grey" }}
              />
            </FormGroup>
            <FormGroup>
              <Input
                placeholder="Last Name"
                onChange={(e) => this.setState({ lastName: e.target.value })}
                style={{ color: "grey" }}
              />
            </FormGroup>
            <FormGroup>
              <Input
                placeholder="Email"
                onChange={(e) => this.setState({ email: e.target.value })}
                style={{ color: "grey" }}
              />
            </FormGroup>


          </Form>
        </ReactBSAlert>
      ),
    });
  };
}
const mapStateToProps = (state) => {
  console.log('User', state.users)
  return { users: state.users };
};
export default connect(mapStateToProps, {})(Users);
