import React, { Component } from "react";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import AuthLayout from './layouts/Auth/Auth.jsx';
import AdminLayout from './layouts/Admin/Admin.jsx';
import RTLLayout from './layouts/RTL/RTL.jsx';

import './assets/css/nucleo-icons.css';
import './assets/scss/black-dashboard-pro-react.scss?v=1.0.0';
// import "assets/demo/demo.css";
import "react-notification-alert/dist/animate.css";

import './assets/demo/demo.css'
import firebase, {auth} from "./utils/firebase";
import { connect } from "react-redux";
import { onAuthStateChanged } from "firebase/auth";


import {
  getDestinations,
  getCategories,
  getTasks,
  getSponsors,
  getContactPerson,
  getBookings,
  getConsultants,
  getUsers,
  getEvents,
  getPaymentRequests,
  getAds,
  getCommissionUsers,
  getCoupons,
  getCerts,
  getCertNumber
} from "./store/actions";

import '@fortawesome/fontawesome-svg-core/styles.css'

const hist = createBrowserHistory();
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
    };
  }

  componentDidMount() {

    this.props.getDestinations();
    this.props.getCategories();
    this.props.getTasks()
    this.props.getSponsors()
    this.props.getContactPerson()
    this.props.getBookings()
    this.props.getConsultants()
    this.props.getUsers()
    this.props.getEvents()
    this.props.getPaymentRequests()
    this.props.getAds()
    this.props.getCommissionUsers()
    this.props.getCoupons()
    this.props.getCerts()
    this.props.getCertNumber()
  }



  render() {
   
    return (
      <Router history={hist}>
        <Switch>
         {!auth.currentUser?(<>
          <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
          <Redirect from="/" to="/auth/login" />
          </>
          )

          :(
          <>
          <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
          <Route path="/rtl" render={(props) => <RTLLayout {...props} />} />
          <Redirect from="/" to="/admin/dashboard" />
          </>
          )
          }
          
        </Switch>
      </Router>
    );
  }
}

const matStateToProps = (state) => {
  return {auth : state.authenticated};
};

export default connect(
  matStateToProps,
  { getDestinations, 
    getCategories, 
    getTasks, 
    getSponsors, 
    getContactPerson,
    getBookings,
    getConsultants,
    getUsers,
    getEvents,
    getPaymentRequests,
    getAds,
    getCommissionUsers,
    getCoupons,
    getCerts,
    getCertNumber
  }
)(App);
