import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
} from "reactstrap";
import firebase, { auth, firestore } from "../../utils/firebase";
import { connect } from "react-redux";
import {
  getDestinations,
  getCategories,
  getTasks,
  getSponsors,
  getContactPerson,
  getBookings,
  getConsultants,
  getUsers,
  getEvents,
  getPaymentRequests,
  getAds,
  getCommissionUsers,
  setAuthenticated,
  getCoupons
} from "../../store/actions";
import { signInWithEmailAndPassword } from "firebase/auth";
import { collection, getDocs, onSnapshot, query, where } from 'firebase/firestore';
import { createBrowserHistory } from "history";

const hist = createBrowserHistory()
class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      error: '',
    };
  }
  componentDidMount() {
    document.body.classList.toggle("login-page");
  }
  componentWillUnmount() {
    document.body.classList.toggle("login-page");
  }



  handeleSubmit = async (e) => {
    e.preventDefault();

    const coll = collection(firestore, 'admin_users')
    const q = query(coll, where('email', '==', this.state.email))
    const data = await getDocs(q)


    if (data.docs.length > 0) {
      signInWithEmailAndPassword(auth, this.state.email, this.state.password).then(()=>{
        hist.push('/admin/dashboard')
      })
      this.props.setAuthenticated(true);
      this.props.getDestinations();
      this.props.getCategories();
      this.props.getTasks()
      this.props.getSponsors()
      this.props.getContactPerson()
      this.props.getBookings()
      this.props.getConsultants()
      this.props.getUsers()
      this.props.getEvents()
      this.props.getPaymentRequests()
      this.props.getAds()
      this.props.getCommissionUsers()
      this.props.getCoupons()
    }
    else {
      this.setState({ error: 'You are not Authorized' })
    }
  };

  render() {
    const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return (
      <>
        <div className="content">
          <Container>
            <Col className="ml-auto mr-auto" lg="4" md="6">
              <Form className="form">
                <Card className="card-login card-white">
                  <CardHeader>
                    <img
                      alt="..."
                      src={require("../../assets/img/card-primary.png")}
                    />
                    <CardTitle tag="h1">Login</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="tim-icons icon-email-85" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Email"
                        type="text"
                        defaultValue={this.state.email}
                        onChange={(e) =>
                          this.setState({ email: e.target.value.replace(/^\s+|\s+$|\s+(?=\s)/g, "") })
                        }
                      />
                    </InputGroup>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="tim-icons icon-lock-circle" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Password"
                        type="password"
                        defaultValue={this.state.password}
                        onChange={(e) =>
                          this.setState({ password: e.target.value })
                        }
                      />
                    </InputGroup>
                  </CardBody>
                  <CardFooter>
                    <Button
                      block
                      className="mb-3"
                      color="primary"
                      href="#pablo"
                      onClick={(e) => this.handeleSubmit(e)}
                      size="lg"
                    >
                      Login
                    </Button>
                    <div className="pull-left">
                      <h6 style={{ color: '#ff0000' }}>

                        {this.state.error}

                      </h6>
                    </div>
                    <div className="pull-right">
                      <h6>
                        <a
                          className="link footer-link"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          Need Help?
                        </a>
                      </h6>
                    </div>
                  </CardFooter>
                </Card>
              </Form>
            </Col>
          </Container>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps, { setAuthenticated, getDestinations, 
  getCategories, 
  getTasks, 
  getSponsors, 
  getContactPerson,
  getBookings,
  getConsultants,
  getUsers,
  getEvents,
  getPaymentRequests,
  getAds,
  getCommissionUsers, getCoupons })(Login);
