import React from "react";
import { connect } from "react-redux";
import firebase, { firestore } from "../utils/firebase";
import ReactBSAlert from "react-bootstrap-sweetalert";
import NotificationAlert from "react-notification-alert";
import moment from "moment";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Table,
  Form,
  UncontrolledTooltip,
} from "reactstrap";
import { Component } from "react";
import ImageUpload from "../components/CustomUpload/ImageUpload";
import { addDoc, collection, Timestamp, deleteDoc, doc } from 'firebase/firestore'

class Ads extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      name: "",
      ad: "",
      company: "",
      hasLink: false,
      link: '',
      endDate: new Date(),
      startDate: new Date(),
      visible: true,
    };
  }

  createAd = async () => {

    await addDoc(collection(firestore, 'ads'), {
      name: this.state.name,
      ad: this.state.ad,
      company: this.state.company,
      hasLink: true,
      link: this.state.link,
      endDate: Timestamp.fromDate(new Date(new Date().setFullYear(new Date().getFullYear() + 1))),
      startDate: Timestamp.fromDate(new Date()),
    }).then(() => {
      this.hideAlert();
      this.notify("bl", "The Sponsor was successfully created");
    });

  };

  removeSponsor = async(ad) => {
await deleteDoc(doc(firestore, 'ads', ad))
      .then(() => {
        this.notify("bl", "The Ad was successfully REMOVED");
      })
  }

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  setImage = (url, type) => {
    this.setState({
      ad: url,
    });
  };

  removeImage = () => {
    this.setState({
      ad: "",
    });
  };

  notify = (place, message) => {
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>{message}</div>
        </div>
      ),
      type: "success",
      icon: "tim-icons icon-bell-55",
      autoDismiss: 7,
    };
    this.refs.notificationAlert.notificationAlert(options);
  };

  render() {
    if (!this.props.ads) {
      return null;
    }

    return (
      <div className="content">
        <Card >
          {this.state.alert}
          <NotificationAlert ref="notificationAlert" />
          <CardHeader>
            <h6 className="title d-inline">Ads({this.props.ads.length})</h6>
            {/* <p className="card-category d-inline">today</p> */}
            <Button
              color="success"
              caret
              id="tooltip342"
              title=""
              className="btn-link btn-icon"
              type="button"
              style={{ float: "right" }}
              onClick={(e) => this.htmlAlert()}
            >
              <i className="tim-icons icon-simple-add" />
            </Button>
            <UncontrolledTooltip delay={0} target="tooltip342">
              Add Ad
            </UncontrolledTooltip>
          </CardHeader>
          <CardBody>
            <div className="table-full-width table-responsive">
              <Table>
                <thead>
                  <th>Ad</th>
                  <th>Company</th>
                  <th>Name</th>
                  <th>Action</th>
                </thead>
                <tbody>
                  {this.props.ads.map((sponsor) => {
                    return (
                      <tr key={sponsor.id}>
                        <td><img src={sponsor.data().ad} alt="" width='70' /></td>
                        <td>
                          <h6 className="title">{sponsor.data().company}</h6>
                        </td>
                        <td>
                          <h6 className="title">{sponsor.data().name}</h6>
                        </td>
                        <td>
                          <Button
                            className="btn-link btn-icon"
                            color="danger"
                            size="sm"
                            title="Renew"
                            type="button"
                            onClick={() => {
                              this.removeAd(sponsor.id)

                            }}><i className="tim-icons icon-simple-remove" /></Button></td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </div>
    );
  }

  htmlAlert = () => {
    this.setState({
      alert: (
        <ReactBSAlert
          style={{ display: "block", marginTop: "-400px", }}
          title="ADD AN Ad"
          onConfirm={() => this.createAd()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          btnSize=""
          showCancel
          confirmBtnText="Create Task!"
          cancelBtnText="Cancel"
        >
          <Form action="#">
            <ImageUpload
              setImage={this.setImage}
              name={"Ads"}
              type={"Profile"}
              index={0}
              removeImage={this.removeImage}
            />
            <FormGroup>
              <Input
                placeholder="Company"
                onChange={(e) => this.setState({ company: e.target.value })}
                style={{ color: "grey" }}
              />
            </FormGroup>
            <FormGroup>
              <Input
                placeholder="Name"
                onChange={(e) => this.setState({ name: e.target.value })}
                style={{ color: "grey" }}
              />
            </FormGroup>
            {/* 
            <FormGroup>
              <label style={{color:'#000', textAlign:'left'}}>Start Date</label>
              <Datetime  value={this.state.startDate} onChange={(e) => this.setState({ startDate: e })}/>
            </FormGroup>

            <FormGroup>
              <label style={{color:'#000', textAlign:'left'}}>End Date</label>
              <Datetime  value={this.state.endDate} onChange={(e) => this.setState({ endDate: e })} />
            </FormGroup> */}

            <FormGroup>
              <label style={{ color: '#000', textAlign: 'left' }}>Link</label>
              <Input
                type="text"
                name="link"
                id="exampleText"
                onChange={(e) => this.setState({ link: e.target.value })}
                placeholder="https://sponsor-webseite.com"
                style={{ color: "grey" }}
              />
            </FormGroup>
          </Form>
        </ReactBSAlert>
      ),
    });
  };
}
const mapStateToProps = (state) => {
  return { ads: state.ads };
};
export default connect(mapStateToProps, {})(Ads);
