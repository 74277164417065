import { combineReducers } from 'redux'
import { getConsultants } from '../actions'

const destinationsReducer = (destinations = null, action) => {
    if (action.type === 'GET_DESTINATIONS') {
        return action.payload
    }
    return destinations
}
const selectedDestinationReducer = (selectedDestination = null, action) => {
    if (action.type === 'DESTINATION_SELECTED') {
        return action.payload;
    }
    return selectedDestination;
}

const dataLoadedReducer = (bool = false, action) => {
    if (action.type === 'DATA_LOADED') {
        return action.payload;
    }
    return bool;
}

const tasksLoadedReducer = (bool = false, action) => {
    if (action.type === 'TASKS_LOADED') {
        return action.payload;
    }
    return bool;
}

const getCategoriesReducer = (categories = null, action) => {
    if (action.type === 'GET_CATEGORIES') {
        return action.payload
    }
    return categories;
}
const getTasksReducer = (tasks = null, action) => {
    if (action.type === 'GET_TASKS') {
        return action.payload
    }
    return tasks
}

const setAuthenticatedReducer = (auth = false, action) => {
    if (action.type === 'SET_AUTHENTICATED') {
        return action.payload
    }

    return auth;
}

const getSponsorReducer = (sponsors = null, action) => {
    if (action.type === 'GET_SPONSORS') {
        return action.payload
    }

    return sponsors;
}

const getContactPersonReducer = (contacts = null, action) => {
    if (action.type === 'GET_CONTACT_PERSON') {
        return action.payload
    }
    return contacts;
}


const getBookingsRedducer = (bookings = null, action) => {
    if (action.type === 'GET_BOOKINGS') {
        return action.payload
    }
    return bookings;
}


const getConsultantsReducer = (consultants = [], action) => {
    if (action.type === 'GET_CONSULTANTS') {
        return action.payload
    }

    return consultants
}

const getUsersReducer = (users = [], action) => {
    if (action.type === 'GET_USERS') {
        return action.payload
    }

    return users
}

const getEventsReducer = (events = [], action) => {
    if (action.type === 'GET_EVENTS') {
        return action.payload
    }

    return events;
}

const getPaymentRequestsReducer = (paymentRequests = [], action) => {

    if (action.type === 'GET_PAYMENT_REQUESTS') {
        return action.payload
    }
    return paymentRequests
}


 const getAdsReducer = (ads = [], action) => {
    if (action.type === 'GET_ADS') {
        return action.payload
    }
    return ads
}
const getCommissionUsersReducer =(users=[], action)=>{
    if(action.type==='GET_COMMISSION_USERS'){
        return action.payload
    }
    return users
}

const getCertNumberReducer =(certNum= {}, action)=>{
    if(action.type === 'GET_CERT_NUMBER'){
        return action.payload
    }
    return certNum
}


const getCouponsReducer =(coupons = null, action)=>{
    if(action.type === 'GET_COUPONS'){
        return action.payload
    }
    return coupons
}

const getCertsReducer =(certs =[], action)=>{
    if(action.type === 'GET_CERTS'){
        return action.payload
    }
    return certs
}


export default combineReducers({
    destinations: destinationsReducer,
    selectedDestination: selectedDestinationReducer,
    categories: getCategoriesReducer,
    tasks: getTasksReducer,
    authenticated: setAuthenticatedReducer,
    sponsors: getSponsorReducer,
    contactPersons: getContactPersonReducer,
    bookings: getBookingsRedducer,
    consultants: getConsultantsReducer,
    users: getUsersReducer,
    events: getEventsReducer,
    paymentRequests: getPaymentRequestsReducer,
    ads:getAdsReducer,
    commissionUsers: getCommissionUsersReducer,
    coupons: getCouponsReducer,
    certs: getCertsReducer,
    certNum:getCertNumberReducer,
})