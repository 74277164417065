import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'

const firebaseConfig = {
  apiKey: "AIzaSyD0aqWnazdqRS5TnUSdN4mYgUmvYLCW0yY",
  authDomain: "island-escapes-f6923.firebaseapp.com",
  databaseURL: "https://island-escapes-f6923.firebaseio.com",
  projectId: "island-escapes-f6923",
  storageBucket: "island-escapes-f6923.appspot.com",
  messagingSenderId: "724118153204",
  appId: "1:724118153204:web:f11e8e4c76307702a1bba1",
  measurementId: "G-STSVQJ8C4Z"
};
// Initialize Firebase
const firebase = initializeApp(firebaseConfig);

export const auth = getAuth(firebase)

export const firestore = getFirestore(firebase)

export const storage = getStorage(firebase)

export default firebase;