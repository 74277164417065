import React from "react";
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux'
import { createStore, applyMiddleware } from "redux";
// import './index.css';
import App from './App';
import reducers from './store/reducers'
import thunk from 'redux-thunk'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import { PersistGate } from 'redux-persist/integration/react'
 
// import rootReducer from './reducers'
 
// const persistConfig = {
//   key: 'root',
//   storage,
// }
 
// const persistedReducer = persistReducer(persistConfig, reducers)
 
// export default () => {
  // let store = createStore(persistedReducer, applyMiddleware(thunk))
  // let persistor = persistStore(store)
//   return { store, persistor }
// }


  
const store = createStore(reducers, applyMiddleware(thunk))
let persistor = persistStore(store);
ReactDOM.render(
  <Provider store={store}>
    {/* <PersistGate loading={null} persistor={persistor}> */}
    <App />
    {/* </PersistGate> */}
  </Provider>,
  document.getElementById('root')
);

