export const roles = [
    {
      value: "ADMIN",
      label: "ADMIN",
    },
    {
      value: "CONSULTANT",
      label: "CONSULTANT",
    },
    {
      value: "MANAGER",
      label: "MANAGER",
    },
    {
      value: "SUPERVISOR",
      label: "SUPERVISOR",
    },
    {
      value: "USER",
      label: "USER",
    },
  ];