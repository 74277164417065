import React from "react";
import { Card, CardHeader, CardBody, CardTitle, Row, Col } from "reactstrap";

import { Line } from "react-chartjs-2";
import { chartExample1 } from "../../variables/charts.jsx";

class ParishChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bigChartData: "data1",
      labels:[
        "Clarendon",
        "Hanover",
        "Kingston",
        "Manchester",
        "St. Andrew",
        "St. Ann",
        "St. Catherine",
        "St. Elizabeth",
        "St. James",
        "St. Mary",
        "St. Thomas",
        "Portland",
        "Trelawny",
        "Westmoreland",
      ],
       clarendon:0,
      hanover:0,
      kingston:0,
      mancheter:0,
      st_andrew:0,
      st_ann:0,
      st_catherine:0,
      st_elizabeth:0,
      st_james:0,
      st_mary:0,
      st_thomas:0,
      portland:0,
      trelawny:0,
      westmoreland:0
    };
  }

  componentDidMount() {
    this.setData();
  }
//   componentWillUpdate() {
//     this.setData();
//   }

  setData = () => {
    //
    var clarendon=0; 
    var hanover = 0;
    var kingston = 0;
    var manchester = 0;
    var st_andrew = 0;
    var st_ann = 0;
    var st_catherine = 0;
    var st_elizabeth = 0;
    var st_james = 0;
    var st_mary = 0;
    var st_thomas = 0;
    var portland = 0;
    var trelawny = 0;
    var westmoreland = 0;


    this.props.data.map((doc) => {
      switch (
        doc.data().location.parish
      ) {
        case "Clarendon":
          clarendon++;
            this.setState({clarendon})
          break;
        case "Hanover":
          hanover++;
            this.setState({hanover})
          break;
        case "Kingston":
          kingston++;
            this.setState({kingston})
          break;
        case "Manchester":
          manchester++;
            this.setState({manchester})
          break;
        case "St. Andrew":
          st_andrew++;
            this.setState({st_andrew})
          break;
        case "St. Ann":
          st_ann++;
            this.setState({st_ann})
          break;
        case "St. Catherine":
          st_catherine++;
            this.setState({st_catherine})
          break;
        case "St. Elizabeth":
          st_elizabeth++;
            this.setState({st_elizabeth})
          break;
        case "St. James":
          st_james++;
            this.setState({st_james})
          break;
        case "St. Mary":
          st_mary++;
            this.setState({st_mary})
          break;
        case "St. Thomas":
          st_thomas++;
            this.setState({st_thomas})
          break;
        case "Portland":
          portland++;
            this.setState({portland})
          break;
          case  "Trelawny":
            trelawny++;
            this.setState({trelawny})
          break;
          case "Westmoreland":
            westmoreland++;
            this.setState({westmoreland})
          break;
        default:
          break;
      }
    });
  };

  setBgChartData = (name) => {
    this.setState({
      bigChartData: name,
    });
  };
  parishData = {
    data1: (canvas) => {
      let ctx = canvas.getContext("2d");

      let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
      gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
      gradientStroke.addColorStop(0, "rgba(29,140,248,0)"); //blue colors

      return {
        labels: this.state.labels,
        datasets: [
          {
            label: "",
            fill: true,
            backgroundColor: gradientStroke,
            borderColor: "#1f8ef1",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: "#1f8ef1",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#1f8ef1",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
           
            data: [
              this.state.clarendon,
              this.state.hanover,
              this.state.kingston,
              this.state.manchester,
              this.state.st_andrew,
              this.state.st_ann,
              this.state.st_catherine,
              this.state.st_elizabeth,
              this.state.st_james,
              this.state.st_mary,
              this.state.st_thomas,
              this.state.portland,
              this.state.trelawny,
              this.state.westmoreland,
            ],
          },
        ],
      };
    },

    options: this.chart_1_2_3_options,
  };

  render() {
      if(!this.props.data){
          return null;
      }
    return (
      <Card className="card-chart">
        <CardHeader>
          <Row>
            <Col className="text-left" sm="6">
              <h5 className="card-category">Total Destinations By</h5>
              <CardTitle tag="h2">
                Parish
                {/* {new Date().getFullYear()} */}
              </CardTitle>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <div className="chart-area">
            <Line
              data={this.parishData[this.state.bigChartData]}
              options={chartExample1.options}
            />
          </div>
        </CardBody>
      </Card>
    );
  }
}

export default ParishChart;
