import React, { Component } from "react";

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Button,
} from "reactstrap";
import Select from "react-select";
import Switch from "react-bootstrap-switch";
import { connect } from "react-redux";
import firebase, { firestore} from "../utils/firebase";
import { packages } from "../data/packages";
import { categories } from "../data/categories";
import parishes from "../data/parishes";
import { openingDays } from "../data/openingDays";
import { openingTime } from "../data/openingTime";
import classnames from "classnames";
import {
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaYoutube,
  FaRoad,
  FaCity,
  FaEnvelope,
  FaPhoneAlt,
  FaGlobeAmericas,
  FaDollarSign,
  FaTags
} from "react-icons/fa";
import { BsGeoAlt, BsPerson } from "react-icons/bs";
import { SiGooglemybusiness } from "react-icons/si";
import { MdLocalMovies } from "react-icons/md";
import { AiOutlineCloseCircle } from "react-icons/ai";

import ImageUpload from "../components/CustomUpload/ImageUpload";
import Loading from "../components/Loading";
import { Message } from "../api/messaging";
import { SendEmail } from "../utils/sendEmail";
import { createBrowserHistory } from "history";
import { doc, addDoc, collection, updateDoc, GeoPoint, deleteDoc, getDocs, onSnapshot, orderBy, query, where, Timestamp } from "firebase/firestore";



const hist = createBrowserHistory();
class EditDestination extends Component {
  constructor(props) {
    super(props);

    this.state = {
      imageActive: true,
      active: this.props.destination ? this.props.destination.data().active : '',
      category: this.props.destination ? this.props.destination.data().category : '',
      email: this.props.destination ? this.props.destination.data().contact.email : '',
      expiredAt: this.props.destination ? this.props.destination.data().expiredAt : '',
      mainPhone: this.props.destination ? this.props.destination.data().contact.mainPhone : '',
      description: this.props.destination ? this.props.destination.data().description : '',
      featured: this.props.destination ? this.props.destination.data().featured : '',
      latitude: this.props.destination ? this.props.destination.data().geocode.latitude : '',
      longitude: this.props.destination ? this.props.destination.data().geocode.longitude : '',
      parish: this.props.destination ? this.props.destination.data().location.parish : '',
      street: this.props.destination ? this.props.destination.data().location.street : '',
      town: this.props.destination ? this.props.destination.data().location.town : '',
      name: this.props.destination ? this.props.destination.data().name : '',
      facebook: this.props.destination ? this.props.destination.data().social.facebook : '',
      instagram: this.props.destination ? this.props.destination.data().social.instagram : '',
      twitter: this.props.destination ? this.props.destination.data().social.twitter : '',
      website: this.props.destination ? this.props.destination.data().social.website : '',
      youtube: this.props.destination ? this.props.destination.data().social.youtube : '',
      images: this.props.destination ? this.props.destination.data().images : '',
      video: this.props.destination ? this.props.destination.data().video : '',
      openingHours: this.props.destination ? this.props.destination.data().openingHours : '',
      package: this.props.destination ? this.props.destination.data().package : '',
      pricing: this.props.destination.data().pricing ? this.props.destination.data().pricing : [],
      salesConsultant: this.props.destination.data().salesConsultant ? this.props.destination.data().salesConsultant : '',

      contactEmail: this.props.contactPerson
        ? this.props.contactPerson.data().email
        : "",
      contactFirstName: this.props.contactPerson
        ? this.props.contactPerson.data().firstName
        : "",
      contactLastName: this.props.contactPerson
        ? this.props.contactPerson.data().lastName
        : "",
      contactPhone: this.props.contactPerson
        ? this.props.contactPerson.data().phone
        : "",
      buttonActive: false,
    };
  }

  componentDidMount() {
    if (this.state.name != "") {
      this.setState({ imageActive: false });
    }
  }

  removeDestination = async () => {

    await deleteDoc(doc(firestore, 'places', this.props.destination.id)).then(() => {
      hist.push('/admin/destinations')
    })
    // await firebase.firestore().collection('places').doc(this.props.destination.id).delete().then(() => {
    //   hist.push('/admin/destinations')
    // })

  }


  setImage = (url, type) => {
    switch (type) {
      case "Profile":
        this.state.images[0] = url;
        break;
      case "Gallery":
        this.setState({ images: [...this.state.images, url] });
        break;
      default:
        break;
    }
  };


  handleRemove() {
    const imageRef = firebase.storage().ref(
      `Destinations/${this.props.name}/${this.state.file.name}`
    );

    imageRef.delete().then(() => {
      this.removeImage(this.props.index);
    });
  }

  removeImage = (index) => {
    if (index === 0) {
      // this.state.images[0] = "";
      this.state.images.splice(index, 1);
    } else {
      this.state.images.splice(index, 1);
      this.notify("bl", "The image was successfully removed");
    }
  };

  removePackage = (index) => {
    
      this.state.pricing.splice(index, 1);
      this.setState({pricing: [...this.state.pricing]})
      // this.notify("bl", "The Package was successfully removed");

  };

  notify = (place, message) => {
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>{message}</div>
        </div>
      ),
      type: "success",
      icon: "tim-icons icon-bell-55",
      autoDismiss: 7,
    };
    this.refs.notificationAlert.notificationAlert(options);
  };

  setDestinations = async (e) => {
    e.preventDefault();

    const aDoc = await updateDoc(doc(firestore, "places", this.props.destination.id), {
      active: this.state.active,
      category: this.state.category,
      contact: {
        email: this.state.email,
        mainPhone: this.state.mainPhone,
      },
      salesConsultant: this.state.salesConsultant,
      expiredAt: this.state.expiredAt,
      description: this.state.description,
      featured: this.state.featured,
      geocode: new GeoPoint(this.state.latitude, this.state.longitude),
      location: {
        parish: this.state.parish,
        street: this.state.street,
        town: this.state.town,
      },
      name: this.state.name,
      social: {
        facebook: this.state.facebook,
        instagram: this.state.instagram,
        twitter: this.state.twitter,
        website: this.state.website,
        youtube: this.state.youtube,
      },
      images: this.state.images,
      video: this.state.video,
      openingHours: this.state.openingHours,
      package: this.state.package,
      pricing: this.state.pricing,
    })



    if (this.state.package === 'Commission') {
      this.addCommissionUser(this.props.destination.id)
    }

    this.addContact(this.props.destination.id);

  };




  addContact = async (id) => {
    if (this.props.contactPerson) {

      await updateDoc(doc(firestore, 'contact_person', this.props.contactPerson.id), {
        firstName: this.state.contactFirstName,
        lastName: this.state.contactLastName,
        phone: this.state.contactPhone,
        email: this.state.contactEmail,
        destination: id,
      }
      )
    } else {
      await addDoc(doc(firestore, 'contact_person', this.props.contactPerson.id), {
        firstName: this.state.contactFirstName,
        lastName: this.state.contactLastName,
        phone: this.state.contactPhone,
        email: this.state.contactEmail,
        destination: id,
      }
      )
    }
  };



  addCommissionUser = async (id) => {

    const coll = collection(firestore, 'commissionUsers')
    const q = query(coll, where('email', '==', this.state.contactEmail))
    onSnapshot(q, async(querySnapshot) => {
      
       if(querySnapshot.docs.length === 0){
        await addDoc(collection(firestore, 'commissionUsers'), {
          dateAdded: Timestamp.fromDate(new Date()),
          email: this.state.contactEmail,
          destination: id,
          role: 'Admin',
          name: `${this.state.contactFirstName} ${this.state.contactLastName}`
        })
       }
     
    })

    
  }



  addField = () => {
    this.setState({ images: [...this.state.images, ""] });
  };

  addTime = () => {
    this.setState({ openingHours: [...this.state.openingHours, {}] });
  };

  addPricing = () => {
    this.setState({ pricing: [...this.state.pricing, {}] });
  };

  sendMessage = (id, name) => {

    SendEmail();
  }


  render() {
    if (!this.props.destination) {
      return <Loading />;
    }

    const consultants = this.props.consultants.map(doc => {
      return {

        value: doc.id,
        label: `${doc.data().firstName} ${doc.data().lastName}`,

      }
    })

    return (
      <div className="content">
        <Row>
          <Col lg="8" md="6">
            <Card style={{ height: 330 }}>
              <CardHeader>
                <CardTitle tag="h5">General Information</CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md={12}>
                    <InputGroup
                      className={classnames(this.state.nameState, {
                        "input-group-focus": this.state.nameFocus,
                      })}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <SiGooglemybusiness />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        name="name"
                        placeholder="Name"
                        defaultValue={this.state.name}
                        type="text"
                        onChange={(e) =>
                          this.setState({
                            name: e.target.value,
                            imageActive: false,
                          })
                        }
                        onFocus={(e) => this.setState({ nameFocus: true })}
                        onBlur={(e) => this.setState({ nameFocus: false })}
                      />
                      {this.state.firstnameState === "has-danger" ? (
                        <label className="error">This field is required.</label>
                      ) : null}
                    </InputGroup>
                  </Col>

                  <Col md={6}>
                    <InputGroup
                      className={classnames(this.state.emailState, {
                        "input-group-focus": this.state.emailFocus,
                      })}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <FaEnvelope />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        name="email"
                        placeholder="Email"
                        type="email"
                        defaultValue={this.state.email}
                        onChange={(e) =>
                          this.setState({ email: e.target.value })
                        }
                        onFocus={(e) => this.setState({ emailFocus: true })}
                        onBlur={(e) => this.setState({ emailFocus: false })}
                      />
                      {this.state.firstnameState === "has-danger" ? (
                        <label className="error">This field is required.</label>
                      ) : null}
                    </InputGroup>
                  </Col>
                  <Col md={6}>
                    <InputGroup
                      className={classnames(this.state.phoneState, {
                        "input-group-focus": this.state.phoneFocus,
                      })}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <FaPhoneAlt />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        name="phone"
                        placeholder="Phone Number"
                        type="text"
                        defaultValue={this.state.mainPhone}
                        onChange={(e) =>
                          this.setState({ mainPhone: e.target.value })
                        }
                        onFocus={(e) => this.setState({ phoneFocus: true })}
                        onBlur={(e) => this.setState({ phoneFocus: false })}
                      />
                      {this.state.firstnameState === "has-danger" ? (
                        <label className="error">This field is required.</label>
                      ) : null}
                    </InputGroup>
                  </Col>
                  <Col md={6}>
                    <Select
                      className="react-select info"
                      classNamePrefix="react-select"
                      name="category"
                      defaultValue={categories.filter(
                        (e) => e.value === this.state.category
                      )}
                      onChange={(e) => this.setState({ category: e.value })}
                      options={categories}
                      placeholder="Select Category"
                    />
                    <div style={{ marginTop: 10 }}>
                      <Select
                        className="react-select info"
                        classNamePrefix="react-select"
                        name="package"
                        defaultValue={packages.filter(
                          (e) => e.value === this.state.package
                        )}
                        onChange={(e) => this.setState({ package: e.value })}
                        options={packages}
                        placeholder="Select Package"
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <Row>
                      <Col md="6">
                        <p className="category">Active</p>
                        <Switch
                          defaultValue={this.state.active}
                          offColor=""
                          onColor="#00ffff"
                          offText="No"
                          onText="Yes"
                          onChange={(value) =>
                            this.setState({ active: !this.state.active })
                          }
                        />
                      </Col>
                      <Col md="6">
                        <p className="category">Featured</p>
                        <Switch
                          defaultValue={this.state.featured}
                          offColor=""
                          onColor=""
                          offText="No"
                          onText="Yes"
                          onChange={(value) =>
                            this.setState({ featured: !this.state.featured })
                          }
                        />
                      </Col>
                      <Col md={12}>
                        <Select
                          className="react-select info"
                          classNamePrefix="react-select"
                          name="consultant"
                          defaultValue={consultants.filter(
                            (e) => e.value === this.state.salesConsultant
                          )}
                          onChange={(e) => this.setState({ salesConsultant: e.value })}
                          options={consultants}
                          placeholder="Select Consultant"
                        />
                      </Col>


                    </Row>
                  </Col>

                  {
                    this.props.commissionUsers && this.state.package === 'Commission'  &&
                    <Col md={12}>
                      <p>({this.props.commissionUsers.length}) Users Commission Users Found</p>
                    </Col>
                  }
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col lg="4" md="6">
            <Card style={{ height: 330 }}>
              <CardHeader>
                <CardTitle tag="h5"> Profile Picture</CardTitle>
              </CardHeader>
              <CardBody className="text-center">
                {this.state.images[0] === "" ? (
                  <ImageUpload
                    setImage={this.setImage}
                    active={this.state.imageActive}
                    name={this.state.name}
                    type={"Profile"}
                    index={0}
                    removeImage={this.removeImage}
                  />
                ) : (
                  <div>

                    <AiOutlineCloseCircle
                      onClick={() => this.removeImage(0)}
                      style={{
                        position: "absolute",
                        left: 20,
                        top: 2,
                        width: 25,
                        height: 25,
                        color: "#ff0000",
                        cursor: 'pointer'
                      }}
                    />

                    <img
                      src={this.state.images[0]}
                      alt=""
                      style={{
                        maxHeight: 259,
                        marginBottom: 20,
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    />
                  </div>
                  // <img src={this.state.images[0]} alt="" style={{height:250}}/>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg="4" md="6">
            <Card>
              <CardHeader>
                <CardTitle tag="h5">Contact Person</CardTitle>
              </CardHeader>
              <CardBody>
                <InputGroup
                  className={classnames(this.state.firstNametState, {
                    "input-group-focus": this.state.firstNameFocus,
                  })}
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <BsPerson />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    name="firstName"
                    placeholder="First Name"
                    type="text"
                    disabled={
                      this.state.package === "Free" || this.state.package === ""
                    }
                    defaultValue={this.state.contactFirstName}
                    onChange={(e) =>
                      this.setState({ contactFirstName: e.target.value })
                    }
                    onFocus={(e) => this.setState({ firstNameFocus: true })}
                    onBlur={(e) => this.setState({ firstNameFocus: false })}
                  />
                  {this.state.firstnameState === "has-danger" ? (
                    <label className="error">This field is required.</label>
                  ) : null}
                </InputGroup>
                <InputGroup
                  className={classnames(this.state.lastNameState, {
                    "input-group-focus": this.state.lastNameFocus,
                  })}
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <BsPerson />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    name="lastName"
                    placeholder=" Last Name"
                    type="text"
                    disabled={
                      this.state.package === "Free" || this.state.package === ""
                    }
                    defaultValue={this.state.contactLastName}
                    onChange={(e) =>
                      this.setState({ contactLastName: e.target.value })
                    }
                    onFocus={(e) => this.setState({ lastNameFocus: true })}
                    onBlur={(e) => this.setState({ lastNameFocus: false })}
                  />
                  {this.state.firstnameState === "has-danger" ? (
                    <label className="error">This field is required.</label>
                  ) : null}
                </InputGroup>

                <InputGroup
                  className={classnames(this.state.contactPhoneState, {
                    "input-group-focus": this.state.contactPhoneFocus,
                  })}
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <FaPhoneAlt />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    name="contactPhone"
                    placeholder="Phone Number"
                    type="text"
                    disabled={
                      this.state.package === "Free" || this.state.package === ""
                    }
                    defaultValue={this.state.contactPhone}
                    onChange={(e) =>
                      this.setState({ contactPhone: e.target.value })
                    }
                    onFocus={(e) => this.setState({ contactPhoneFocus: true })}
                    onBlur={(e) => this.setState({ contactPhoneFocus: false })}
                  />
                  {this.state.firstnameState === "has-danger" ? (
                    <label className="error">This field is required.</label>
                  ) : null}
                </InputGroup>

                <InputGroup
                  className={classnames(this.state.contactEmailState, {
                    "input-group-focus": this.state.contactEmailFocus,
                  })}
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <FaEnvelope />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    name="contactEmail"
                    placeholder="Email Address"
                    type="text"
                    disabled={
                      this.state.package === "Free" || this.state.package === ""
                    }
                    defaultValue={this.state.contactEmail}
                    onChange={(e) =>
                      this.setState({ contactEmail: e.target.value })
                    }
                    onFocus={(e) => this.setState({ contactEmailFocus: true })}
                    onBlur={(e) => this.setState({ contactEmailFocus: false })}
                  />
                  {this.state.firstnameState === "has-danger" ? (
                    <label className="error">This field is required.</label>
                  ) : null}
                </InputGroup>
              </CardBody>
            </Card>
          </Col>
          <Col lg="8" md="6">
            <Card>
              <CardHeader>
                <CardTitle tag="h5">Description</CardTitle>
              </CardHeader>
              <CardBody>
                <Input
                  type="textarea"
                  id="description"
                  defaultValue={this.state.description}
                  onChange={(e) =>
                    this.setState({ description: e.target.value })
                  }
                  placeholder="Description"
                  style={{ padding: 10, minHeight: 200 }}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg="4" md="6">
            <Card>
              <CardHeader>
                <CardTitle tag="h5">Destination Address</CardTitle>
              </CardHeader>
              <CardBody>
                <InputGroup
                  className={classnames(this.state.streetState, {
                    "input-group-focus": this.state.streetFocus,
                  })}
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <FaRoad />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    name="street"
                    placeholder="Street"
                    type="text"
                    defaultValue={this.state.street}
                    onChange={(e) => this.setState({ street: e.target.value })}
                    onFocus={(e) => this.setState({ streetFocus: true })}
                    onBlur={(e) => this.setState({ streetFocus: false })}
                  />
                  {this.state.firstnameState === "has-danger" ? (
                    <label className="error">This field is required.</label>
                  ) : null}
                </InputGroup>
                <InputGroup
                  className={classnames(this.state.townState, {
                    "input-group-focus": this.state.townFocus,
                  })}
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <FaCity />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    name="town"
                    placeholder="Town"
                    defaultValue={this.state.town}
                    type="text"
                    onChange={(e) => this.setState({ town: e.target.value })}
                    onFocus={(e) => this.setState({ townFocus: true })}
                    onBlur={(e) => this.setState({ townFocus: false })}
                  />
                  {this.state.firstnameState === "has-danger" ? (
                    <label className="error">This field is required.</label>
                  ) : null}
                </InputGroup>

                <Select
                  className="react-select info"
                  classNamePrefix="react-select"
                  name="parish"
                  defaultValue={parishes.filter(
                    (e) => e.value === this.state.parish
                  )}
                  onChange={(e) => this.setState({ parish: e.value })}
                  options={parishes}
                  placeholder="Select Parish"
                />

                <Row style={{ marginTop: 10 }}>
                  <Col lg="6" md="6">
                    <InputGroup
                      className={classnames(this.state.latitudeState, {
                        "input-group-focus": this.state.latitudeFocus,
                      })}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <BsGeoAlt />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        name="latitude"
                        placeholder="Latitude"
                        type="text"
                        defaultValue={this.state.latitude}
                        onChange={(e) =>
                          this.setState({ latitude: e.target.value })
                        }
                        onFocus={(e) => this.setState({ latitudeFocus: true })}
                        onBlur={(e) => this.setState({ latitudeFocus: false })}
                      />
                      {this.state.firstnameState === "has-danger" ? (
                        <label className="error">This field is required.</label>
                      ) : null}
                    </InputGroup>
                  </Col>
                  <Col lg="6" md="6">
                    <InputGroup
                      className={classnames(this.state.longitudeState, {
                        "input-group-focus": this.state.longitudeFocus,
                      })}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <BsGeoAlt />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        name="longitude"
                        placeholder="Longitude"
                        type="text"
                        defaultValue={this.state.longitude}
                        onChange={(e) =>
                          this.setState({ longitude: e.target.value })
                        }
                        onFocus={(e) => this.setState({ longitudeFocus: true })}
                        onBlur={(e) => this.setState({ longitudeFocus: false })}
                      />
                      {this.state.firstnameState === "has-danger" ? (
                        <label className="error">This field is required.</label>
                      ) : null}
                    </InputGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col lg="4" md="6">
            <Card>
              <CardHeader>
                <CardTitle tag="h5">Social Media</CardTitle>
              </CardHeader>
              <CardBody>
                <InputGroup
                  className={classnames(this.state.facebookState, {
                    "input-group-focus": this.state.facebookFocus,
                  })}
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      {/* <i className="tim-icons icon-single-02" /> */}
                      <FaFacebookF />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    name="facebook"
                    placeholder="Facebook"
                    type="text"
                    disabled={
                      this.state.package === "Free" ||
                      this.state.package === "" ||
                      this.state.package === "Basic"
                    }
                    defaultValue={this.state.facebook}
                    onChange={(e) =>
                      this.setState({ facebook: e.target.value })
                    }
                    onFocus={(e) => this.setState({ facebookFocus: true })}
                    onBlur={(e) => this.setState({ facebookFocus: false })}
                  />
                  {this.state.firstnameState === "has-danger" ? (
                    <label className="error">This field is required.</label>
                  ) : null}
                </InputGroup>
                <InputGroup
                  className={classnames(this.state.instagramState, {
                    "input-group-focus": this.state.instagramFocus,
                  })}
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <FaInstagram />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    name="instagram"
                    placeholder="Instagram"
                    type="text"
                    disabled={
                      this.state.package === "Free" ||
                      this.state.package === "" ||
                      this.state.package === "Basic"
                    }
                    defaultValue={this.state.instagram}
                    onChange={(e) =>
                      this.setState({ instagram: e.target.value })
                    }
                    onFocus={(e) => this.setState({ instagramFocus: true })}
                    onBlur={(e) => this.setState({ instagramFocus: false })}
                  />
                  {this.state.firstnameState === "has-danger" ? (
                    <label className="error">This field is required.</label>
                  ) : null}
                </InputGroup>
                <InputGroup
                  className={classnames(this.state.twitterState, {
                    "input-group-focus": this.state.twitterFocus,
                  })}
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <FaTwitter />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    name="twitter"
                    placeholder="Twitter"
                    type="text"
                    disabled={
                      this.state.package === "Free" ||
                      this.state.package === "" ||
                      this.state.package === "Basic"
                    }
                    defaultValue={this.state.twitter}
                    onChange={(e) => this.setState({ twitter: e.target.value })}
                    onFocus={(e) => this.setState({ twitterFocus: true })}
                    onBlur={(e) => this.setState({ twitterFocus: false })}
                  />
                  {this.state.firstnameState === "has-danger" ? (
                    <label className="error">This field is required.</label>
                  ) : null}
                </InputGroup>
                <InputGroup
                  className={classnames(this.state.youtubeState, {
                    "input-group-focus": this.state.youtubeFocus,
                  })}
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <FaYoutube />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    name="youtube"
                    placeholder="Youtube"
                    type="text"
                    disabled={
                      this.state.package === "Free" ||
                      this.state.package === "" ||
                      this.state.package === "Basic"
                    }
                    defaultValue={this.state.youtube}
                    onChange={(e) => this.setState({ youtube: e.target.value })}
                    onFocus={(e) => this.setState({ youtubeFocus: true })}
                    onBlur={(e) => this.setState({ youtubeFocus: false })}
                  />
                  {this.state.firstnameState === "has-danger" ? (
                    <label className="error">This field is required.</label>
                  ) : null}
                </InputGroup>
              </CardBody>
            </Card>
          </Col>

          <Col lg="4" md="6">
            <Card style={{ height: 267 }}>
              <CardHeader>
                <CardTitle tag="h5">Other Information</CardTitle>
              </CardHeader>
              <CardBody>
                <InputGroup
                  className={classnames(this.state.websiteState, {
                    "input-group-focus": this.state.websiteFocus,
                  })}
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <MdLocalMovies />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    name="video"
                    placeholder="Youtube Video Id"
                    type="text"
                    defaultValue={this.state.video}
                    disabled={
                      this.state.package === "Free" ||
                      this.state.package === "" ||
                      this.state.package === "Basic"
                    }
                    onChange={(e) => this.setState({ video: e.target.value })}
                    onFocus={(e) => this.setState({ firstnameFocus: true })}
                    onBlur={(e) => this.setState({ firstnameFocus: false })}
                  />
                  {this.state.firstnameState === "has-danger" ? (
                    <label className="error">This field is required.</label>
                  ) : null}
                </InputGroup>
                <InputGroup
                  className={classnames(this.state.websiteState, {
                    "input-group-focus": this.state.websiteFocus,
                  })}
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <FaGlobeAmericas />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    name="website"
                    placeholder="Website"
                    type="text"
                    disabled={this.state.package != "Premium" && this.state.package != 'Commission'}
                    defaultValue={this.state.website}
                    onChange={(e) => this.setState({ website: e.target.value })}
                    onFocus={(e) => this.setState({ websiteFocus: true })}
                    onBlur={(e) => this.setState({ websiteFocus: false })}
                  />
                  {this.state.firstnameState === "has-danger" ? (
                    <label className="error">This field is required.</label>
                  ) : null}
                </InputGroup>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg={this.state.package === 'Commission' ? "6" : "12"} md={this.state.package === 'Commission' ? "6" : "12"}>
            <Card style={{ minHeight: 267 }}>
              <CardHeader>
                <CardTitle tag="h5">Opening Hours</CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  {this.state.openingHours.map((time, i) => (
                    <Col md={this.state.package === 'Commission' ? "12" : "6"}>
                      <Row style={{ marginBottom: 20 }}>
                        <Col md={4}>
                          <Select
                            className="react-select info"
                            classNamePrefix="react-select"
                            name="openingDays"
                            defaultValue={openingDays.filter(
                              (e) => e.value === this.state.openingHours[i].name
                            )}
                            onChange={(e) => {
                              this.state.openingHours[i].name = e.value;
                              this.setState({
                                openingHours: [...this.state.openingHours],
                              });
                            }}
                            options={openingDays}
                            placeholder="Select Day"
                          />
                        </Col>
                        <Col md={4}>
                          <Select
                            className="react-select info"
                            classNamePrefix="react-select"
                            name="openingTime"
                            defaultValue={openingTime.filter(
                              (e) => e.value === this.state.openingHours[i].open
                            )}
                            onChange={(e) => {
                              this.state.openingHours[i].open = e.value;
                              this.setState({
                                openingHours: [...this.state.openingHours],
                              });
                            }}
                            options={openingTime}
                            placeholder="Opening Time"
                          />
                        </Col>
                        <Col md={4}>
                          <Select
                            className="react-select info"
                            classNamePrefix="react-select"
                            name="Closing"
                            defaultValue={openingTime.filter(
                              (e) =>
                                e.value === this.state.openingHours[i].close
                            )}
                            onChange={(e) => {
                              this.state.openingHours[i].close = e.value;
                              this.setState({
                                openingHours: [...this.state.openingHours],
                              });
                            }}
                            options={openingTime}
                            placeholder="Closing Time"
                          />
                        </Col>
                      </Row>
                    </Col>
                  ))}
                </Row>
                <div>
                  <Button
                    onClick={this.addTime}
                    disabled={this.state.package != "Premium" && this.state.package != "Commission"}
                  >
                    Add New Opening Hours
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Col>
          {this.state.package === 'Commission' &&
            (<Col md={this.state.package === 'Commission' ? '6' : '12'}>
              <Card style={{ minHeight: 267 }}>
                <CardHeader>
                  <CardTitle tag="h5">Pricing Details</CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                    {this.state.pricing.map((time, i) => (
                      <Col md='12'>
                        <Row style={{ marginBottom: 20 }}>

                          <Col md={12}>


                            <InputGroup
                              className={classnames(this.state.pricingNameState, {
                                "input-group-focus": this.state.pricingNameFocus,
                              })}
                            >
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <FaTags />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                name="pricingName"
                                placeholder="Pricing Name"
                                defaultValue={this.state.pricing[i].name}
                                type="text"
                                onChange={(e) => {
                                  this.state.pricing[i].name = e.target.value;
                                  this.setState({
                                    pricing: [...this.state.pricing],
                                  });
                                }}
                                onFocus={(e) => this.setState({ pricingNameFocus: true })}
                                onBlur={(e) => this.setState({ pricingNameFocus: false })}
                              />
                              {this.state.pricingNameState === "has-danger" ? (
                                <label className="error">This field is required.</label>
                              ) : null}
                            </InputGroup>
                          </Col>


                          {/* {this.state.pricing[i].isPackage && 
                         <Col md={6}>
                            <InputGroup
                              className={classnames(this.state.pricingAdultState, {
                                "input-group-focus": this.state.pricingAdultFocus,
                              })}
                            >
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <FaDollarSign />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                name="pricingAdult"
                                placeholder="Package Cost"
                                type="number"
                                defaultValue={this.state.pricing[i].cost}
                                onChange={(e) => {
                                  this.state.pricing[i].cost = e.target.value;
                                  this.setState({
                                    pricing: [...this.state.pricing],
                                  });
                                }}
                                onFocus={(e) => this.setState({ pricingAdultFocus: true })}
                                onBlur={(e) => this.setState({ pricingAdultFocus: false })}
                              />
                              {this.state.pricingAdultState === "has-danger" ? (
                                <label className="error">This field is required.</label>
                              ) : null}
                            </InputGroup>
                          </Col>}

                          {!this.state.pricing[i].isPackage && 
                          <>
                         <Col md={6}>
                            <InputGroup
                              className={classnames(this.state.pricingAdultState, {
                                "input-group-focus": this.state.pricingAdultFocus,
                              })}
                            >
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <FaDollarSign />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                name="pricingAdult"
                                placeholder="Adult Price"
                                type="number"
                                defaultValue={this.state.pricing[i].adult}
                                onChange={(e) => {
                                  this.state.pricing[i].adult = e.target.value;
                                  this.setState({
                                    pricing: [...this.state.pricing],
                                  });
                                }}
                                onFocus={(e) => this.setState({ pricingAdultFocus: true })}
                                onBlur={(e) => this.setState({ pricingAdultFocus: false })}
                              />
                              {this.state.pricingAdultState === "has-danger" ? (
                                <label className="error">This field is required.</label>
                              ) : null}
                            </InputGroup>
                          </Col>
                          <Col md={6}>
                            <InputGroup
                              className={classnames(this.state.pricingChildState, {
                                "input-group-focus": this.state.pricingChildFocus,
                              })}
                            >
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <FaDollarSign />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                name="pricingAdult"
                                placeholder="Child Price"
                                type="number"
                                defaultValue={this.state.pricing[i].child}
                                onChange={(e) => {
                                  this.state.pricing[i].child = e.target.value;
                                  this.setState({
                                    pricing: [...this.state.pricing],
                                  });
                                }}
                                onFocus={(e) => this.setState({ pricingChildFocus: true })}
                                onBlur={(e) => this.setState({ pricingChildFocus: false })}
                              />
                              {this.state.pricingAdultState === "has-danger" ? (
                                <label className="error">This field is required.</label>
                              ) : null}
                            </InputGroup>
                          </Col>
                          </>} */}

                          <Col md="12">
                            <p className="category">Is a Package</p>
                            <Switch
                              defaultValue={this.state.pricing[i].isPackage || false}
                              offColor="red"
                              onColor="#00ffff"
                              offText="No"
                              onText="Yes"
                              onChange={(value) => {
                                this.state.pricing[i].isPackage = value.state.value;
                                this.setState({
                                  pricing: [...this.state.pricing],
                                });
                              }}
                            />
                          </Col>

                          {this.state.pricing[i].isPackage &&
                            <>
                              <Col md={12}>
                                <InputGroup
                                  className={classnames(this.state.pricingAdultState, {
                                    "input-group-focus": this.state.pricingAdultFocus,
                                  })}
                                >
                                  <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                      <FaDollarSign />
                                    </InputGroupText>
                                  </InputGroupAddon>
                                  <Input
                                    name="pricingAdult"
                                    placeholder="Package Cost"
                                    type="number"
                                    defaultValue={this.state.pricing[i].cost}
                                    onChange={(e) => {
                                      this.state.pricing[i].cost = e.target.value;
                                      this.setState({
                                        pricing: [...this.state.pricing],
                                      });
                                    }}
                                    onFocus={(e) => this.setState({ pricingAdultFocus: true })}
                                    onBlur={(e) => this.setState({ pricingAdultFocus: false })}
                                  />
                                  {this.state.pricingAdultState === "has-danger" ? (
                                    <label className="error">This field is required.</label>
                                  ) : null}
                                </InputGroup>
                              </Col>



                            </>}

                          {this.state.pricing[i].isPackage &&
                            <>
                              <Col md={6}>
                                <InputGroup
                                  className={classnames(this.state.adultQuantityState, {
                                    "input-group-focus": this.state.adultQuantityFocus,
                                  })}
                                >
                                  <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                      {/* <FaDollarSign /> */}
                                    </InputGroupText>
                                  </InputGroupAddon>
                                  <Input
                                    name="packageAdultd"
                                    placeholder="Adult Quantity"
                                    type="number"
                                    defaultValue={this.state.pricing[i].adultQuantity}
                                    onChange={(e) => {
                                      this.state.pricing[i].adultQuantity = e.target.value;
                                      this.setState({
                                        pricing: [...this.state.pricing],
                                      });
                                    }}
                                    onFocus={(e) => this.setState({ pricingAdultFocus: true })}
                                    onBlur={(e) => this.setState({ pricingAdultFocus: false })}
                                  />
                                  {this.state.pricingAdultState === "has-danger" ? (
                                    <label className="error">This field is required.</label>
                                  ) : null}
                                </InputGroup>
                              </Col>
                              <Col md={6}>
                                <InputGroup
                                  className={classnames(this.state.childQunatityState, {
                                    "input-group-focus": this.state.childQunatityFocus,
                                  })}
                                >
                                  <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                      {/* <FaDollarSign /> */}
                                    </InputGroupText>
                                  </InputGroupAddon>
                                  <Input
                                    name="packageChild"
                                    placeholder="Children Quantity"
                                    type="number"
                                    defaultValue={this.state.pricing[i].childQunatity}
                                    onChange={(e) => {
                                      this.state.pricing[i].childQunatity = e.target.value;
                                      this.setState({
                                        pricing: [...this.state.pricing],
                                      });
                                    }}
                                    onFocus={(e) => this.setState({ childQunatityFocus: true })}
                                    onBlur={(e) => this.setState({ childQunatityFocus: false })}
                                  />
                                  {this.state.pricingAdultState === "has-danger" ? (
                                    <label className="error">This field is required.</label>
                                  ) : null}
                                </InputGroup>
                              </Col>
                            </>}

                          {!this.state.pricing[i].isPackage &&
                            <>
                              <Col md={6}>
                                <InputGroup
                                  className={classnames(this.state.pricingAdultState, {
                                    "input-group-focus": this.state.pricingAdultFocus,
                                  })}
                                >
                                  <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                      <FaDollarSign />
                                    </InputGroupText>
                                  </InputGroupAddon>
                                  <Input
                                    name="pricingAdult"
                                    placeholder="Adult Price"
                                    type="number"
                                    defaultValue={this.state.pricing[i].adult}
                                    onChange={(e) => {
                                      this.state.pricing[i].adult = e.target.value;
                                      this.setState({
                                        pricing: [...this.state.pricing],
                                      });
                                    }}
                                    onFocus={(e) => this.setState({ pricingAdultFocus: true })}
                                    onBlur={(e) => this.setState({ pricingAdultFocus: false })}
                                  />
                                  {this.state.pricingAdultState === "has-danger" ? (
                                    <label className="error">This field is required.</label>
                                  ) : null}
                                </InputGroup>
                              </Col>
                              <Col md={6}>
                                <InputGroup
                                  className={classnames(this.state.pricingChildState, {
                                    "input-group-focus": this.state.pricingChildFocus,
                                  })}
                                >
                                  <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                      <FaDollarSign />
                                    </InputGroupText>
                                  </InputGroupAddon>
                                  <Input
                                    name="pricingAdult"
                                    placeholder="Child Price"
                                    type="number"
                                    defaultValue={this.state.pricing[i].child}
                                    onChange={(e) => {
                                      this.state.pricing[i].child = e.target.value;
                                      this.setState({
                                        pricing: [...this.state.pricing],
                                      });
                                    }}
                                    onFocus={(e) => this.setState({ pricingChildFocus: true })}
                                    onBlur={(e) => this.setState({ pricingChildFocus: false })}
                                  />
                                  {this.state.pricingAdultState === "has-danger" ? (
                                    <label className="error">This field is required.</label>
                                  ) : null}
                                </InputGroup>
                              </Col>
                            </>}
                          {/* <Col md={6}>
                          <InputGroup
                              className={classnames(this.state.pricingChildState, {
                                "input-group-focus": this.state.pricingChildFocus,
                              })}
                            >
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <FaDollarSign/>
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                name="pricingChild"
                                placeholder="Child Price"
                                type="number"
                                defaultValue={this.state.pricing[i].child}
                                onChange={(e) => {
                                  this.state.pricing[i].child = e.target.value;
                                  this.setState({
                                    pricing: [...this.state.pricing],
                                  });
                                }}
                                onFocus={(e) => this.setState({ pricingChildFocus: true })}
                                onBlur={(e) => this.setState({ pricingChildFocus: false })}
                              />
                              {this.state.pricingChildState === "has-danger" ? (
                                <label className="error">This field is required.</label>
                              ) : null}
                            </InputGroup>
                          </Col> */}
                          <Col md='12'>
                            <Input
                              type="textarea"
                              id="details"
                              defaultValue={this.state.pricing[i].details}
                              onChange={(e) => {
                                this.state.pricing[i].details = e.target.value;
                                this.setState({ pricing: [...this.state.pricing] })
                              }}
                              placeholder="Pricing Details"
                              style={{ padding: 10, height: 192, maxHeight: 192 }}
                            />
                          </Col>
                          <Col md={12}>
                             <Button style={{width:'100%'}} onClick={()=>this.removePackage(i)}>Remove Package</Button>
                          </Col>
                         
                        </Row>
                      </Col>

                    ))}
                  </Row>
                  <div>
                    <Button onClick={this.addPricing}>Add New Pricing</Button>
                  </div>
                </CardBody>
              </Card>
            </Col>)
          }
        </Row>
        <Row>
          <Col lg="12" md="12">
            <Card style={{ minHeight: 267 }}>
              <CardHeader>
                <CardTitle tag="h5">
                  Gallery Images ({this.state.images.length})
                </CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  {this.state.images.map((image, i) => (
                    <Col md={4}>
                      {/* {this.checkImages(i+1)} */}
                      {this.state.images[i + 1] === "" ? (
                        <ImageUpload
                          key={i + 1}
                          setImage={this.setImage}
                          active={this.state.imageActive}
                          name={this.state.name}
                          type={"Gallery"}
                          index={i + 1}
                          removeImage={this.removeImage}
                        />
                      ) : (
                        <div>
                          {i + 1 < this.state.images.length ? (
                            <AiOutlineCloseCircle
                              onClick={() => this.removeImage(i + 1)}
                              style={{
                                position: "absolute",
                                left: 20,
                                top: 2,
                                width: 25,
                                height: 25,
                                color: "#ff0000",
                                cursor: 'pointer'
                              }}
                            />
                          ) : null}
                          <img
                            src={this.state.images[i + 1]}
                            alt=""
                            style={{
                              maxHeight: 259,
                              marginBottom: 20,
                              marginLeft: "auto",
                              marginRight: "auto",
                            }}
                          />
                        </div>
                      )}
                    </Col>
                  ))}
                </Row>
                <Button
                  onClick={() => this.addField()}
                  disabled={this.state.buttonActive}
                >
                  Add Image
                </Button>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col
            lg={6}
            className="ml-auto mr-auto"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Button color="success" onClick={(e) => this.setDestinations(e)}>
              Update Destination
            </Button>

            <Button color="danger" onClick={(e) => this.removeDestination()}>
              DELETE DESTINATION
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
  checkImages = (num) => {
    if (num < 10 && this.state.package === "Free") {
      this.setState({ buttonActive: false });
    }

    if (num < 25 && this.state.package === "Basic") {
      this.setState({ buttonActive: false });
    }

    if (num < 40 && this.state.package === "Standard") {
      this.setState({ buttonActive: false });
    }

    if (num < 55 && this.state.package === "Premium") {
      this.setState({ buttonActive: false });
    }
  };
}

const mapStateToProps = (state) => {

  const contactPerson = state.contactPersons.filter(
    (des) => des.data().destination === state.selectedDestination.id
  );

  const commissionUsers = state.commissionUsers.filter(
    (des) => des.data().destination === state.selectedDestination.id
  );


  // categories.filter(
  //   (e) => e.value === this.state.category
  return {
    destination: state.selectedDestination,
    contactPerson: contactPerson[0],
    consultants: state.consultants,
    commissionUsers:commissionUsers
  };
};

export default connect(mapStateToProps, {})(EditDestination);
