

import emailjs, { init } from '@emailjs/browser';
init("H9BACQf4VbBrgJNn3");

export const SendEmail =()=>{
    var templateParams = {
        email: 'lobntrav@gmail.com',
        destination: 'test destination',
        total:50,
        amount:2,
        package:'test package'
    };
    emailjs.send("service_cor1r12","template_ztaiven",templateParams);

}