export const categories = [
    {
      value: "m6MbHmzXsc5D84J8JxUm",
      label: "CAMPING",
    },
    {
      value: "PxSPNks98sBJ5KpoOvrX",
      label: "BEACHES",
    },
    // {
    //   value: "nO2Xix1m3POsVc4CJAME",
    //   label: "CAFE",
    // },
    {
      value: "s6PhdjpYMKjr1w5MHRHz",
      label: "ACCOMMADATIONS",
    },
    {
      value: "IxrzoBwElKyGhJNqMJTg",
      label: "FOOD SPOTS",
    },
    {
      value: "Qwp1cbkDWktrz2ttXRV1",
      label: "RIVERS",
    },

    {
      value: "KuWbe3slfitBaNZkOtOH",
      label: "THINGS TO DO",
    },
    {
      value: "JtJ7uU31wCIDcYxnvsUR",
      label: "TRANSPORTATION",
    },
  ];