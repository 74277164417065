import React, { Component } from "react";
import {Link} from 'react-router-dom'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  UncontrolledTooltip,
  
} from "reactstrap";
import moment from "moment";
import { connect } from "react-redux";
import { createBrowserHistory } from "history";
import { selectDestination } from '../../store/actions'

const hist = createBrowserHistory();

class RenewalTable extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }


  handelClick=(doc)=>{
    this.props.selectDestination(doc)
    hist.push('/admin/edit_destination')
  }

  render() {
    return (
      <Card style={{height:473,overflow:'scroll'}}>
        <CardHeader>
          <CardTitle tag="h5"> Renewal Management({this.props.data.length})</CardTitle>
        </CardHeader>
        <CardBody>
          <Table responsive>
            <thead className="text-primary">
              <tr>
                  <th></th>
                <th>Name</th>
                <th>Package</th>
                <th>Renewal</th>
                {/* <th>Cost</th> */}
                <th>Actions</th>
              </tr>
            </thead>
            <tbody style={{height:430,overflow:'scroll'}}>
              {this.props.data.map((doc) => {
                return<tr key={doc.id}>
                  <td className="text-center">
                    <div className="photo">
                      <img alt="..." src={doc.data().images[0]} style={{height:'100%'}}/>
                    </div>
                  </td>
                  <td>{doc.data().name}</td>
                  <td>{doc.data().package}</td>
                  {/* <td>{moment.utc(doc.data().expiredAt.toDate())}</td> */}
                  <td>{moment
                  .utc(doc.data().expiredAt.toDate())
                  .local()
                  .startOf('seconds')
                  .fromNow()}</td>
                  {/* <td>€ 99,225</td> */}
                  <td>
                    <Link
                      className="btn-link btn-icon"
                      to='/admin/edit_destination'
                      color="success"
                      id={`tooltip${doc.id}`}
                      size="sm"
                      title="Renew"
                      type="button"
                      onClick={()=>this.props.selectDestination(doc)}
                    >
                      <i className="tim-icons icon-pencil" />
                    </Link>
                    <UncontrolledTooltip delay={0} target={`tooltip${doc.id}`}>
                      Edit Destination
                    </UncontrolledTooltip>
                    {/* <Button
                      className="btn-link btn-icon"
                      color="danger"
                      id="tooltip707467505"
                      size="sm"
                      title="Cancel"
                      type="button"
                    >
                      <i className="tim-icons icon-simple-remove" />
                    </Button>
                    <UncontrolledTooltip delay={0} target="tooltip707467505">
                      Cancel
                    </UncontrolledTooltip> */}
                  </td>
                </tr>;
              })}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    categories: state.categories,
  };
};

export default connect(
  mapStateToProps,
  {selectDestination}
)(RenewalTable);
