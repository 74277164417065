import React, { Component } from "react";
import classNames from "classnames";
// react component for creating dynamic tables
import ReactTable from "react-table";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Button, 
  UncontrolledTooltip
} from "reactstrap";
import {Link} from 'react-router-dom'
import moment from "moment";
import { connect } from 'react-redux'



class Events extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.events?.map((doc) => {
        return {
          id: doc.id,
          capacity: doc.data().capacity,
          name: doc.data().name,
          category: doc.data().category,
          time: doc.data().startTime,
          date: moment
            .utc(doc.data().date.toDate())
            .local()
            .startOf('seconds')
            .fromNow(),
          location: doc.data().to,
          cost: doc.data().cost,
          actions: (
            // we've added some custom button actions
            <div className="actions-right" key={doc.id}>
              
              {/* use this button to add a edit kind of action */}
              <Link
                      className="btn-link btn-icon btn-success"
                      to='/admin/edit_event'
                      color="success"
                      id={`tooltip${doc.id}`}
                      size="sm"
                      title="Renew"
                      type="button"
                      onClick={()=>this.props.selectEvent(doc)}
                    >
                      <i className="tim-icons icon-pencil" />
                    </Link>{" "}
             
            </div>
          )
        };
      })
    };
  }

  
  render() {


    return (
      <>
        <div className="content">
          <Col md={8} className="ml-auto mr-auto">
            <h2 className="text-center">All {this.props.events.length} Events</h2>
           
          </Col>
          <Row className="mt-5">
            <Col xs={12} md={12}>
              <Card>
                <CardHeader>
                  {/* <CardTitle tag="h4">React Table</CardTitle> */}
                  <Link
                      className="btn-link btn-icon btn-success"
                      to='/admin/create_event'
                      color="success"
                      id={'tooltip342'}
                      size="sm"
                      title="Renew"
                      style={{ float: "right" }}
                      type="button"
                    >
                       <i className="tim-icons icon-simple-add" />
                    </Link>
                
            <UncontrolledTooltip delay={0} target="tooltip342">
              Add Event
            </UncontrolledTooltip>
                </CardHeader>
                <CardBody>
                  <ReactTable
                    data={this.state.data}
                    filterable
                    resizable={false}
                    columns={[
                      {
                        Header: "Event",
                        accessor: "name"
                      },
                      {
                        Header: "Category",
                        accessor: "category"
                      },
                      {
                        Header: "Event Date",
                        accessor: "date"
                      },
                      {
                        Header: "Remaining Tickets",
                        accessor: "capacity",
                        filterable: false
                      },
                      {
                        Header:'Location',
                        accessor:'location',
                      },
                      {
                        Header:'Start Time',
                        accessor:'time',
                        sortable: false,
                        filterable: false
                      },
                      // {
                      //   Header:'Children',
                      //   accessor:'children',
                      //   sortable: false,
                      //   filterable: false
                      // },
                      {
                        Header:'Ticket Cost',
                        accessor:'cost',
                        sortable: false,
                        filterable: false
                      },
                      {
                        Header: "Actions",
                        accessor: "actions",
                        sortable: false,
                        filterable: false
                      }
                    ]}
                    defaultPageSize={10}
                    showPaginationTop
                    showPaginationBottom={false}
                    className="-striped -highlight"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) =>{

 
  return {events:state.events}
}

export default connect(mapStateToProps, {})(Events);
