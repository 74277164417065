import React, { Component } from "react";
import classNames from "classnames";
// react component for creating dynamic tables
import ReactTable from "react-table";
import { connect } from 'react-redux'
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Button,
  UncontrolledTooltip,
} from "reactstrap";
import {Link} from 'react-router-dom'
import firebase from "../utils/firebase";
import { createBrowserHistory } from "history";
import { selectDestination } from '../store/actions'
import moment from "moment";

const hist = createBrowserHistory();


class Destinations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.destinations?.map((doc) => {
        return {
          id: doc.id,
          active: doc.data().active? 'Active':'Deactive',
          attraction: doc.data().name,
          package: doc.data().package,
          expired: moment
            .utc(doc.data().expiredAt.toDate())
            .local()
            .startOf('seconds')
            .fromNow(),
          parish: doc.data().location.parish,
          actions: (
            // we've added some custom button actions
            <div className="actions-right" key={doc.id}>
              
              {/* use this button to add a edit kind of action */}
              <Link
                      className="btn-link btn-icon btn-success"
                      to='/admin/edit_destination'
                      color="success"
                      id={`tooltip${doc.id}`}
                      size="sm"
                      title="Renew"
                      type="button"
                      onClick={()=>this.props.selectDestination(doc)}
                    >
                      <i className="tim-icons icon-pencil" />
                    </Link>{" "}
             
            </div>
          )
        };
      })
    };
  }



  handelClick=(doc)=>{
    this.props.selectDestination(doc)
    hist.push('/admin/edit_destination')
  }
  getCategory= async(id)=>{
    var name=''
    await firebase.firestore().collection('categories').doc(id).get().then((doc)=>{
      name=doc.data.name
    })
    return name;
  }


  render() {
    return (
      <>
        <div className="content">
          <Col md={8} className="ml-auto mr-auto">
            <h2 className="text-center">All {this.props.destinations.length} Destinations </h2>
       
          </Col>
          <Row className="mt-5">
            <Col xs={12} md={12}>
              <Card>
                <CardHeader>
                <Link
                      className="btn-link btn-icon btn-success"
                      to='/admin/add_destination'
                      color="success"
                      id={'tooltip342'}
                      size="sm"
                      title="Renew"
                      style={{ float: "right" }}
                      type="button"
                    >
                       <i className="tim-icons icon-simple-add" />
                    </Link>
                {/* <Button
              color="success"
              caret
              id="tooltip342"
              title=""
              className="btn-link btn-icon"
              type="button"
              style={{ float: "right" }}
              onClick={(e) => this.htmlAlert()}
            >
              <i className="tim-icons icon-simple-add" />
            </Button> */}
            <UncontrolledTooltip delay={0} target="tooltip342">
              Add Destination
            </UncontrolledTooltip>

                </CardHeader>
                <CardBody>
                  <ReactTable
                    data={this.state.data}
                    filterable
                    resizable={false}
                    columns={[
                      {
                        Header:"Active",
                        accessor:"active"
                      },
                      {
                        Header: "Attraction",
                        accessor: "attraction"
                      },
                      
                      {
                        Header: "Package",
                        accessor: "package",
                      },
                      {
                        Header: "Expired",
                        accessor: "expired",
                      },
                      {
                        Header: "Parish",
                        accessor: "parish",
                      },
                      {
                        Header: "Actions",
                        accessor: "actions",
                        sortable: false,
                        filterable: false
                      }
                    ]}
                    defaultPageSize={10}
                    showPaginationTop
                    showPaginationBottom={false}
                    className="-striped -highlight"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

const mapStateToProps =(state)=>{
  return { destinations: state.destinations}
}

export default connect(mapStateToProps, {selectDestination})(Destinations);
